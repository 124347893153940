.footer {
    padding: 2rem;
    background-color: #40485A;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0rem;
    box-sizing: border-box;
}

.footer section:first-child ul {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.footer section:first-child ul li a {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
}

.footer section:last-child {
    border-top: 1px solid #57BAC4;
    padding-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer section:last-child p {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}

.footer section:last-child div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer section:last-child div ul {
    display: flex;
    align-items: center;
    gap: 8px;
}

.footer section:last-child div ul li a span {
    width: 28px;
    height: 28px;
    background-color: #7181A1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.footer section:last-child div ul li a span svg {
    color: #fff;
}

@media only screen and (min-width:768px) {
    .footer section:first-child ul {
        justify-content: flex-end;
    }

    .footer {
        padding: 2rem 4rem;
    }
}