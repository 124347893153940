/* .contents {
  background-color: #f0f2f5;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 0 solid rgba(0, 0, 0, 0.125);
  position: relative;
  padding-top: 6rem;
  margin-top: 2rem;
} */
.section-view {
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: column;
 gap: 1rem;
}
.admin-container .content-wrapper .section-view section:first-child {
 overflow: hidden;
 width: 100%;
 height: max-content;
 margin-bottom: 2rem;
 padding: 0rem 3rem 0rem 3rem;
 display: flex;
 flex-direction: column;
 gap: 1rem;
}

.admin-container .content-wrapper .section-view section:first-child .image {
 position: relative;
 border-radius: 8px 8px 0px 0px;
 overflow: hidden;
 height: 400px;
}

.admin-container .content-wrapper .section-view section:first-child .image img {
 object-fit: cover;
 width: 100%;
 height: 100%;
}
.section-view section:first-child .images {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 gap: 1rem;
 position: relative;
 margin: 0rem auto;
 overflow: auto;
 height: max-content;
}
.admin-container .content-wrapper .section-view section:first-child .images li {
 height: 150px;
 max-width: 250px;
 overflow: hidden;
 border-radius: 8px;
}

.admin-container .content-wrapper .section-view section:first-child .images li img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.admin-container .content-wrapper .section-view section:first-child .images span {
 position: absolute;
 padding: 2px 6px;
 background-color: #878da4cc;
 color: #fff;
 display: flex;
 gap: 6px;
 align-items: center;
 border-radius: 4px;
 font-size: 12px;
 bottom: 0px;
 right: 0px;
}

@media only screen and (min-width: 1024px) {
 .admin-container .content-wrapper .section-view section:first-child {
  flex-direction: row;
  height: 400px;
 }

 .section-view {
  flex-direction: row;
}

 .admin-container .content-wrapper .section-view section:first-child .images {
  grid-template-columns: 1fr;
  width: max-content;
  height: 400px;
 }

 .admin-container .content-wrapper .section-view section:first-child .images li {
  height: 200px;
 }
}
.admin-container .content-wrapper .section-view section:nth-child(2) {
  height: max-content;
  padding: 0rem 1rem 1rem 1rem;
  margin-top: 0px;
}

.outer-box .inner-box {
 height: max-content;
 border: 1px solid #eeeeee;
 background-color: #ffffff;
 border-radius: 16px;
 padding: 1rem 1rem 1rem 1rem;
}
.outer-box .inner-box .top {
 margin: 0.5rem 0rem 0.5rem 0rem;
 padding: 16px 16px 8px 16px;
}

.admin-container .content-wrapper .section-view section:first-child {
  padding: 0px;
}
.admin-container .content-wrapper .section-view section:first-child {
  width: 80%;
}
.outer-box .inner-box .top p:first-child {
 font-size: 16px;
 font-weight: 600;
 line-height: 24px;
 letter-spacing: 0px;
 text-align: left;
 color: #0f1c49;
}
.outer-box .inner-box .top p:last-child {
 font-size: 12px;
 font-weight: 400;
 line-height: 18px;
 letter-spacing: 0px;
 text-align: left;
 color: #585858;
}
.outer-box .inner-box .amount {
 padding: 4px 20px 4px 20px;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.outer-box .inner-box .amount span:last-child {
 font-size: 12px;
 font-weight: 600;
 line-height: 18px;
 letter-spacing: 0px;
 text-align: center;
 color: #0f1c49;
}
.outer-box .inner-box .amount span:first-child {
 font-size: 12px;
 font-weight: 400;
 line-height: 18px;
 letter-spacing: 0px;
 text-align: center;
 color: #828597;
}
.outer-box .inner-box .bid-input {
 padding: 8px 20px 8px 20px;
}
.outer-box .inner-box .bid-input input {
 border: 1px solid #eeeeee;
 color: #878da4;
}
/* .outer-box .inner-box .bid-input p { */
  .outer-box .inner-box .bid-input textarea {
 border: 1px solid #eeeeee;
 color: #878da4;
 padding: 16px;
 border-radius: 16px;
 margin-top: 20px;
 height: 120px;
}
.outer-box .inner-box .bottom {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 20px;
}
.admin-container .content-wrapper .contents > section {
 display: flex;
 flex-direction: column;
 gap: 24px;
 padding: 0rem 3rem 3rem 3rem;
}
.admin-container .content-wrapper .contents .modal-grid {
 display: grid;
 grid-template-columns: 1fr;
 gap: 24px;
}
@media only screen and (min-width: 768px) {
 .admin-container .content-wrapper .contents .modal-grid {
  grid-template-columns: 1fr 1fr;
 }
}
.modal-grid .modal-box1 p {
 font-size: 18px;
 font-weight: 500;
 line-height: 20px;
 letter-spacing: 0em;
 text-align: left;
 color: #0f1c49;
 margin-top: 10px;
}
.modal-grid .modal-box1 p:last-child {
 font-size: 12px;
 font-weight: 400;
 line-height: 14px;
 letter-spacing: 0px;
 text-align: left;
 color: #828597;
 text-decoration: underline;
 margin-top: 5px;
}
.modal-grid .modal-box2 p {
 font-size: 18px;
 font-weight: 500;
 line-height: 20px;
 letter-spacing: 0em;
 text-align: left;
 color: #000000;
 margin-top: 10px;
}
.modal-grid .modal-box2 p:last-child {
 font-size: 12px;
 font-weight: 400;
 line-height: 20px;
 letter-spacing: 0em;
 text-align: left;
 margin-top: 5px;
}

/* congrats  */
.outer-box .confirm-box {
 height: 240px;
 border: 1px solid #eeeeee;
 background-color: #ffffff;
 border-radius: 16px;
 display: flex;
 flex-direction: column;
 align-items: center;
}
.outer-box .confirm-box .top {
 margin: 0.5rem 0rem 0.5rem 0rem;
 padding: 16px 16px 8px 16px;
}

.outer-box .confirm-box .top p:first-child {
 font-size: 16px;
 font-weight: 600;
 line-height: 24px;
 letter-spacing: 0px;
 text-align: center;
 color: #0f1c49;
}
.outer-box .confirm-box .top p:last-child {
 font-size: 12px;
 font-weight: 400;
 line-height: 18px;
 letter-spacing: 0px;
 text-align: center;
 color: #585858;
}
.outer-box .confirm-box .image {
 height: 60px;
 display: flex;
 justify-content: center;
 margin-top: 3%;
}
.outer-box .confirm-box .image .load {
 background: url("../../../Assets/Images/load.svg");
 background-repeat: no-repeat;
 background-size: contain;
 height: 50px;
 width: 50px;
}
.outer-box .confirm-box > p {
 padding: 8px 20px 8px 20px;
 font-size: 12px;
 font-weight: 400;
 line-height: 18px;
 letter-spacing: 0px;
 text-align: center;
 color: #828597;
}
.outer-box .confirm-box .bottom p {
 color: #0084d1;
 font-size: 14px;
 font-weight: 500;
 line-height: 21px;
 letter-spacing: 0px;
 text-align: center;
}
