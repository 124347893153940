.modal-container .modal-popup {
  margin-top: 1.5rem;
  width: calc(100% - 2rem);
  max-width: 544px;
  max-height: calc(100vh - 5rem);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
}

.modal-container .modal-popup .header {
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  padding: 2rem 2rem 1rem 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
}

.modal-container .modal-popup .header h3 {
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}

.modal-container .modal-popup .header button {
  padding: 0rem;
  background-color: transparent;
  border-radius: 0px;
}

.modal-container .modal-popup .content {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1rem 0rem 1rem;
  margin-top: 4rem;
  border-radius: 0px 0px 8px 8px;
  background-color: #ffffff;
}
.modal-container .modal-popup .modal-content .action {
  width: 100%;
  display: flex;
}
.modal-container .modal-popup .modal-content .action .cancel-btn {
  background: url("../../../Assets/Images/cancel.png");
  background-repeat: no-repeat;
  cursor: pointer;
  width: 30px;
  height: 70px;
}
.modal-container .modal-popup .content .image {
  width: 170px;
  height: max-content;
  gap: 3rem;
  padding: 1rem;
  background-color: #ffffff;
}

.modal-container .modal-popup .content .image div {
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 128px;
  height: 128px;
  border-radius: 50%;
  overflow: hidden;
}

.modal-container .modal-popup .content .image div label img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.modal-container .modal-popup .content .image input {
  display: none;
}

.modal-container .modal-popup .content .image p {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
}

.modal-container .modal-popup .content form {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
}

.modal-container .modal-popup .content::-webkit-scrollbar {
  display: none;
}

.modal-container .modal-popup .content form .modal-input-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.modal-container .modal-popup .content form .modal-input-box {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.modal-container .modal-popup .content form .modal-input-box input {
  border-radius: 0rem;
  padding: 8px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: 0.1s all linear;
}

.modal-container
  .modal-popup
  .content
  form
  .modal-input-box:focus-within
  label {
  color: #0084d1;
}

.modal-container
  .modal-popup
  .content
  form
  .modal-input-box:focus-within
  input {
  border-bottom: 2px solid #0084d1;
}

.modal-container .modal-popup .content form .modal-input-box button {
  background-color: transparent;
  color: grey;
  border-radius: 0px;
  padding: 0px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  right: 16px;
  top: -1.5rem;
  /* transform: translateY(-50%); */
}

.modal-container .modal-popup .content form .action-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: center;
  padding: 32px 40px;
  background: #ffffff;
}

.modal-container .modal-popup .content form .action-box button {
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
}

.modal-container .modal-popup .content form .action-box .save {
  background: rgba(233, 235, 240, 0.99);
  border-radius: 100px;
}

.modal-container .modal-popup .content form .action-box .active {
  background-color: #0084d1;
  border-radius: 100px;
}

.modal-container .modal-popup .content form .action-box .cancel {
  color: #0084d1;
  background: transparent;
}

@media only screen and (min-width: 768px) {
  .modal-container .modal-popup .content form .modal-input-grid {
    grid-template-columns: 1fr 1fr;
  }
}

/* confirm box */

.modal-container .modal-popup .modal-content {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3rem 1rem;
  border-radius: 8px;
  background-color: #ffffff;
}

.modal-container .modal-popup .modal-content h3 {
  color: #000000;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
}
.modal-container .modal-popup .modal-content .action-box {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
