.navbar {
  position: fixed;
  top: 0rem;
  left: 0rem;
  z-index: 999;
  background-color: #fff;
  width: 100vw;
  height: 80px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}
.navbar-max-width {
  max-width: 1200px;
  width: auto;
  margin: 0rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 0.2rem;
}
.navbar .logo {
  height: 35px;
  width: 50%;
  cursor: pointer;
}
.navbar .logo .sign {
  background: url("../../Assets/Images/NRICONNECT.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  flex: 1;
}
@media only screen and (min-width: 1024px) {
  .navbar .logo {
    height: 45px;
    width: 20%;
    cursor: pointer;
  }
}
/* .navbar .logo h1 {
  font-size: 36px;
  font-weight: 700;
  color: #0084d1;
} */

.notification-btn svg {
  color: #0084d1;
}

.notification-btn {
  width: max-content;
  position: relative;
}

.notification-btn span {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: #ff5c83;
  font-weight: 400;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.navbar .menu {
  position: fixed;
  width: 100%;
  max-width: 450px;
  top: 0rem;
  right: -450px;
  z-index: 1000;
  background-color: #fff;
  height: 100vh;
  box-sizing: border-box;
  padding: 8rem 3rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  transition: 0.3s all linear;
}

.navbar .menu.active {
  right: 0px;
}

.navbar .menu li:first-child {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.navbar .menu li a {
  color: #272727;
  font-weight: 600;
  font-size: 20px;
}

.navbar .menu li:nth-child(6) {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.navbar .menu li:nth-child(6) span {
  display: flex;
  gap: 4px;
  align-items: center;
}

.navbar .menu li:nth-child(6) svg {
  color: #0084d1;
}

.navbar .menu li:nth-child(6) span {
  font-size: 20px;
  font-weight: 600;
  color: #272727;
}

.navbar .menu li:nth-child(6) button {
  padding: 11px 16px;
}
/*for solutions dropdown*/
.solutions-link {
  cursor: pointer;
}
.solutions-link:hover + .dropdown-menu {
  display: block;
}
.navbar .menu .dropdown-menu li:first-child {
  position: relative;
  top: 0rem;
  right: 0rem;
}
/* CSS for the dropdown menu */
.dropdown-menu {
  position: absolute;
  left: 25%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 10px;
  z-index: 1000; /* Ensure it's above other elements */
}

.navbar .menu .dropdown-menu li {
  display: block;
  margin: 0.2rem 0;
}

.navbar .user-icon {
  padding: 0px;
  background-color: transparent;
}

.navbar .user-settings-menu {
  position: absolute;
  display: flex;
  width: max-content;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 6px 0px 18px rgba(97, 35, 231, 0.06);
  z-index: 1000;
  right: 2rem;
  top: 70px;
  border-radius: 4px;
}

.navbar .user-settings-menu {
  position: absolute;
  top: 70px;
  right: 2rem;
  z-index: 1000;
  background-color: #fff;
  border-radius: 4px;
  padding: 0rem 1rem;
  box-shadow: 6px 0px 18px rgba(97, 35, 231, 0.06);
}

.navbar .user-settings-menu li a,
.navbar .user-settings-menu li button {
  display: flex;
  gap: 8px;
  background-color: transparent;
  padding: 1rem;
  border-radius: 0%;
  color: #585858;
  font-size: 12px;
}

.navbar .user-settings-menu li:first-child {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) {
  .navbar .menu {
    max-width: 350px;
    right: -350px;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar {
    padding: 1rem 2rem;
    /* gap: 4rem; */
  }

  .navbar .menu {
    position: static;
    background-color: transparent;
    box-shadow: none;
    flex-direction: row;
    right: auto;
    top: auto;
    padding: 0rem;
    height: auto;
    width: 100%;
    max-width: 100%;
    align-items: center;
    gap: 1.5rem;
  }

  .navbar .menu li:first-child {
    display: none;
  }
  .navbar .menu .dropdown-menu li:first-child {
    display: block;
  }
  .navbar .menu li a {
    font-size: 16px;
  }

  .navbar .menu li:nth-child(6) {
    flex-direction: row;
  }

  .navbar .menu li:nth-child(6) span {
    font-size: 16px;
    margin-right: 4rem;
  }

  .navbar.active .menu li:nth-child(6) span {
    font-size: 16px;
    margin-right: 40px;
  }

  .nav-btn {
    display: none;
  }

  .nav-btn.notification-btn {
    display: block;
    position: absolute;
    top: 50%;
    right: 16rem;
    z-index: 1000;
    transform: translateY(-50%);
  }

  .navbar.active .nav-btn.notification-btn {
    right: 9.5rem;
  }
}

.admin-header {
  padding: 1rem 2rem;
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  position: relative;
}

.admin-header button {
  width: auto;
  height: auto;
  padding: 0rem;
  background-color: transparent;
  border-radius: 0rem;
}

.admin-header button svg {
  color: #7b809a;
}

.admin-header .sidebar {
  transition: 0.1s all linear;
  position: fixed;
  top: 1rem;
  left: -266px;
  z-index: 999;
  height: calc(100vh - 2rem);
  background-color: #63a4ff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  border-radius: 10px;
  width: 250px;
}

.admin-header .sidebar.active {
  left: 1rem;
}

.admin-header .sidebar .header {
  padding: 2rem 1rem;
  text-align: center;
  position: relative;
}

.admin-header .sidebar .header p {
  color: #272227;
  line-height: 16px;
}

.admin-header .sidebar .header .close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.admin-header .sidebar .header .close-btn svg {
  color: #9f9fa3;
}

.admin-header .sidebar .tabs {
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
  max-height: calc(100vh - 112px);
}

.admin-header .sidebar .tabs .link {
  padding: 12px 1rem;
}

.admin-header .sidebar .tabs .sub-tabs.link {
  padding: 0px;
}

.admin-header .sidebar .tabs .sub-tabs.link ul {
  padding-left: 12px;
}

.admin-header .sidebar .tabs .link a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 0.875rem;
}

.admin-header .sidebar .tabs .link.active {
  background-color: #0084d1;
  border-radius: 6px;
}

.admin-header .sidebar .tabs .button {
  width: 100%;
  display: flex;
  gap: 10px;
  font-weight: 400;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding: 12px 1rem;
}

.admin-header .sidebar .tabs .button svg {
  color: #fff;
}

.admin-header .sidebar .tabs .button.active {
  background-color: #0084d1;
  border-radius: 6px;
  margin-bottom: 0.5rem;
}

.admin-header .sidebar .tabs .link.active a {
  color: #fff;
  font-weight: 300;
}

.admin-header .admin-settings-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  z-index: 1000;
  padding: 0rem 1rem;
  right: 2rem;
  top: 2.5rem;
  border-radius: 4px;
}

.admin-header .admin-settings-menu li {
  padding: 1rem 0rem;
}

.admin-header .admin-settings-menu li:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.admin-header .admin-settings-menu li a,
.admin-header .admin-settings-menu li button {
  display: flex;
  gap: 8px;
  color: #585858;
  font-size: 12px;
  line-height: 18px;
}

.admin-header .admin-settings-menu li svg {
  color: #585858;
}

@media only screen and (min-width: 1024px) {
  .admin-header .sidebar {
    left: 1rem;
  }

  .admin-header .sidebar .header .close-btn,
  .admin-header .open-btn {
    display: none;
  }
}
