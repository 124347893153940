.details .title-deed .heading .download,
.details .fard .heading .download {
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  border-radius: 8px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.details .title-deed .heading .download p,
.details .fard .heading .download p {
  color: #585858;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.details .title-deed .heading .download .arrow,
.details .fard .heading .download .arrow {
  background: url("../../../../Assets/Images/u_arrow-circle-down.png");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.details .title-deed .heading,
.details .fard .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.details .title-deed .heading .info,
.details .fard .heading .info {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: flex-start;
  gap: 5%;
}
.details .title-deed .heading svg,
.details .fard .heading svg {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .details .title-deed .heading svg,
  .details .fard .heading svg {
    display: block;
  }
  .details .title-deed .heading .download,
  .details .fard .heading .download {
    padding: 4px 16px 4px 16px;
    gap: 6px;
  }
}
