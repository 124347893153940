.detail-box {
  width: 100%;
}

.header p:first-child {
  font-size: 30px;
  line-height: 45px;
  font-weight: 500;
  color: #0f1c49;
}
.header p:first-child span {
  color: #e07f51;
}
.header p:last-child {
  margin-top: 10px;
}

.detail-box .details {
  min-height: 500px;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  padding: 20px;
  margin-top: 10%;
}
.detail-box .details button {
  position: absolute;
  display: flex;
  align-items: center;
  width: 170px;
  bottom: 13%;
  right: 28%;
  justify-content: space-between;
}

.detail-box .details > p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  /* margin: 10px; */
  color: #0f1c49;
}

.detail-box .details .personal-details p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  margin: 10px;
}
.detail-box .details .personal-details p:first-child {
  text-transform: capitalize;
}
.detail-box .details label {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  color: #666666;
  margin: 10px;
}
.detail-box .details .input-div {
  margin: 30px 20px 0px 10px;
}

.detail-box .details .phone-details {
  margin: 20px 0 0 10px;
}
.detail-box .details .phone-details p {
  color: #474747;
  font-size: 16px;
  font-weight: 600;
}
.detail-box .details .phone-details .react-tel-input {
  margin-top: 20px;
}
/* .property .enquire {
  padding: 1rem;
  border-radius: 24px;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
} */

.details .personal-details input {
  border: 1px solid #eeeeee;
  color: #878da4;
  width: 95%;
  margin: 3% 0;
}
.details .personal-details input:nth-child(2) {
  margin-bottom: 6%;
}
.details .personal-details .react-tel-input input {
  margin-top: 3%;
}

/* OTP details */

.details .otp-details input {
  border: 1px solid #eeeeee;
  color: #878da4;
  width: 95%;
  margin: 3% 0;
}

.details .otp-details .otp-btn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10%;
}
.details .otp-details .otp-btn button {
  position: static;
  width: max-content;
}
.details .otp-details .otp-btn a {
  text-decoration: none;
  color: #0084d1;
  font-weight: 500;
}
@media only screen and (min-width: 1024px) {
  .detail-box .details button {
    right: 45%;
  }
  .detail-box .details > p {
    margin: 10px;
  }
  .detail-box {
    width: 60%;
  }
}
