.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.sections section:first-child {
  width: 100%;
}
.sections .property-detail {
  width: 100%;
  position: relative;
}
.property-detail .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.property-detail .details {
  min-height: 785px;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  padding: 20px;
  margin-top: 3%;
}
.heading p:first-child {
  font-size: 20px;
  line-height: 45px;
  font-weight: 500;
  color: #0f1c49;
}

.heading p:last-child {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #0f1c49;
}
.sections .details {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.sections .details input {
  border: 1px solid #eeeeee;
  color: #878da4;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  margin: 3% 0;
}
.sections .details select {
  border: 1px solid #eeeeee;
  color: #1f2129be;
  width: 100%;
  background-color: #fff;
  padding: 18px 20px 14px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-radius: 32px;
  transition: 0.1s all linear;
  margin: 3% 0;
}
.details .additional {
  display: grid;
  column-gap: 0%;
  grid-template-columns: 1fr;
}
.details .additional1 {
  display: grid;
  column-gap: 0%;
  grid-template-columns: 1fr;
}
.sections .property-detail .footer-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  /* margin-top: 10%; */
  position: absolute;
  bottom: 2%;
}
.sections .property-detail button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
}
.sections .property-detail button:first-child {
  display: flex;
  text-decoration: none;
  background-color: white;
  color: #0084d1;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 20%;
  /* margin-left: 2%; */
  align-items: center;
  padding: 0px;
}

/* right section */
.sections section:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-score {
  display: flex;
  width: 100%;
  align-items: center;
}
.property-score .score .score-label {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10%;
}
/* PROGRESS BAR */
.property-score .score .score-chart25 {
  border: 16px solid #d8f1ff; /* Blue */
  border-right: 16px solid #0084d1; /* Light grey */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  transform: rotate(-45deg);
}
.property-score .score .score-chart50 {
  border: 16px solid #d8f1ff; /* Light grey */
  border-top: 16px solid #0084d1; /* Blue */
  border-right: 16px solid #0084d1; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  transform: rotate(43deg);
}
.property-score .score .score-chart75 {
  border: 16px solid #0084d1; /* Light grey */
  border-left: 16px solid #d8f1ff; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  transform: rotate(43deg);
}
.property-score .score .score-chart100 {
  border: 16px solid #0084d1; /* Light grey */
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.property-score .score .score-label p {
  position: absolute;
  top: 40%;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.property-score .score {
  min-height: 340px;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  padding: 15px;
}
.score .score-heading {
  margin-top: 10%;
}
.score .score-heading p {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #0f1c49;
}
.score .score-detail {
  margin-top: 10%;
}
.score .score-detail p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.score .autosave {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin-top: 7%;
}
.score .autosave label {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #18aa00;
}
.score .autosave .round-check {
  border: 1px solid #18aa00;
  border-radius: 50%;
  appearance: none;
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
}
.score .autosave .round-check:checked {
  background-color: #18aa00;
}
@media only screen and (min-width: 1024px) {
  .sections {
    flex-direction: row;
    gap: 0;
  }
  .heading p:first-child {
    font-size: 30px;
  }
  .sections section:first-child {
    width: 60%;
  }
  .details .additional {
    column-gap: 5%;
    grid-template-columns: 1.5fr 1fr;
  }
  .details .additional1 {
    column-gap: 5%;
    grid-template-columns: 1fr 1fr;
  }
  .property-score {
    display: flex;
    width: 45%;
    align-items: center;
  }
  .property-score .score {
    border-radius: 8px;
  }
  .sections .property-detail .footer-btn {
    bottom: 5%;
  }
}
