.property .details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.property .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.property .content-wrapper section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.property .content-wrapper section:first-child {
  width: 100%;
}

.property .details .image {
  position: relative;
  border-radius: 8px 8px 0px 0px;
  overflow: hidden;
  height: 250px;
}

.property .details .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.property .details .image span {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #878da4cc;
  padding: 2px 16px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.property .details .header {
  padding: 0rem 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
}

.property .details .header li:first-child h3,
.property .details .header li:last-child span:first-child {
  font-size: 18px;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
}
.property .details .header li:first-child span {
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: capitalize;
  color: #0084d1;
}
.property .details .service-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.property .details .service-card {
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.property .details .service-card img {
  max-width: 100%;
  height: 200px;
}

.property .details .service-card h3 {
  margin-top: 10px;
}
.property .details .services {
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  gap: 2rem 3rem;
  flex-wrap: wrap;
}

.property .details .services li {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.property .details .services li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.property .details .service_name {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 1rem;
}

.property .details .service_name li {
  display: flex;
  gap: 0.5rem;
}

.property .details .service_name li p {
  font-size: 13px;
  font-weight: 400;
  color: #000;
  margin-top: 0;
}

.property .details .service_name li svg {
  /* background: rgba(124, 213, 24, 0.2); */
  /* border: 2px solid #7CD518; */
  margin-top: 4px;
  width: 13px;
  /* background-color: #e3f3ce; */
  height: 13px;
  border-radius: 50%;
}
.property .details p {
  padding: 0rem 1rem;
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.property .details a {
  text-decoration: none;
  color: inherit;
}
.property .images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  position: relative;
  margin: 0rem auto;
  overflow: auto;
  height: 400px;
}

.property .images li {
  height: 150px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 8px;
}

.property .images li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.property .images span {
  position: absolute;
  padding: 2px 6px;
  background-color: #878da4cc;
  color: #fff;
  display: flex;
  gap: 6px;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  bottom: 0px;
  right: 0px;
}

.property .enquire {
  padding: 1rem;
  border-radius: 24px;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.property .enquire input {
  border: 1px solid #eeeeee;
  color: #878da4;
}
.property .enquire textarea {
  border: 1px solid #eeeeee;
  color: #878da4;
}
.property .enquire select {
  border: 1px solid #eeeeee;
  color: #1f2129be;
  width: 100%;
  background-color: #fff;
  padding: 20px 24px 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 32px;
  transition: 0.1s all linear;
}
.property .enquire p {
  padding: 1rem;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  color: #585858;
}

@media only screen and (min-width: 768px) {
  .property .details .image {
    height: 400px;
  }
  .property .details .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1024px) {
  .property .content-wrapper {
    flex-direction: row;
  }

  .property .images {
    grid-template-columns: 1fr;
    width: max-content;
  }

  .property .images li {
    height: 200px;
  }
  .property .details .service-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
