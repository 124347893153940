.viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* Ensure the overlay is behind the popup but above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Basic styling for the popup container */
.viewer-popup {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
  z-index: 1000;
  position: relative;
}
/* Basic styling for the popup container */
.document-viewer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
  z-index: 1000;
}

/* Styling for the header */
.viewer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
}

/* Close button styling */
.viewer-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  padding-right: 10px;
  color: #888;
}

/* Styling for the content area */
.viewer-content {
  padding: 16px;
  max-height: 60vh;
  overflow: auto;
}

/* Styling for images within the viewer */
.viewer-content img {
  max-width: 100%;
  height: 300px;
  display: block;
  margin: 0 auto;
}

/* Iframe styling */
.viewer-content iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
