.details .note {
  margin-bottom: 3%;
}
.details .note span {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff5c83;
}
.details .note p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff5c83;
}
.details .title-deed,
.details .fard {
  margin: 3% 0%;
}
.details .title-deed .heading,
.details .fard .heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.details .title-deed .heading p,
.details .fard .heading p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}
.details .title-deed .upload-data,
.details .fard .upload-data {
  border: 2px dashed #32b3ff;
  margin-top: 3%;
  border-radius: 16px;
  width: 100%;
  /* height: 90px; */
  padding: 5%;
  background-color: #f3f9fd;
}
.details .title-deed .upload-data .data,
.details .fard .upload-data .data {
  background: url("../../../../Assets/Images/Vector.svg");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}
.details .title-deed .upload-data .file-box-actions,
.details .fard .upload-data .file-box-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  border-radius: 16px;
}
.details .title-deed .upload-data .file-box-actions .file-detail1,
.details .fard .upload-data .file-box-actions .file-detail1 {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: flex-end;
  margin-top: 2.5%;
}
.details .title-deed .upload-data .file-box-actions .file-detail1 p:first-child,
.details .fard .upload-data .file-box-actions .file-detail1 p:first-child {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #0f1c49;
  margin-top: 3%;
}
.details .title-deed .upload-data .file-box-actions .file-detail p:first-child,
.details .fard .upload-data .file-box-actions .file-detail p:first-child {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #0f1c49;
  margin-top: 3%;
}
.details .title-deed .upload-data .file-box-actions .file-detail1 .cancel-btn,
.details .fard .upload-data .file-box-actions .file-detail1 .cancel-btn {
  background: url("../../../../Assets/Images/cancel.png");
  background-repeat: no-repeat;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
.details .title-deed .upload-data .file-box-actions .file-detail p,
.details .fard .upload-data .file-box-actions .file-detail p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #222222;
}
@media only screen and (min-width: 1024px) {
  .details .title-deed .upload-data,
  .details .fard .upload-data {
    padding: 0%;
    height: 90px;
  }
}
