.accordion {
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(203, 203, 203, 0.15);
    background-color: #fff;
    width: 100%;
}

.accordion.active {
    background-color: #F9FAFC;
}

.accordion .label {
    padding: 1rem 3rem 1rem 1rem;
    position: relative;
}

.accordion .label p {
    font-size: 16px;
    font-weight: 500;
    color: #585858;
    line-height: 24px;
}

.accordion .label::after {
    content: "+";
    position: absolute;
    top: 50%;
    right: 1rem;
    color: #585858;
    font-size: 24px;
    transform: translateY(-50%);
}

.accordion.active .label::after {
    content: "-";
}

.accordion .content {
    overflow: hidden;
    padding: 0rem 3rem 0rem 1rem;
    height: 0px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.accordion.active .content {
    padding: 0rem 3rem 1rem 1rem;
    overflow: visible;
    opacity: 1;
}

.accordion .content p {
    font-size: 14px;
    font-weight: 500;
    color: #828597;
    line-height: 21px;
}