.auth-container {
    position: relative;
    min-height: 100vh;
    width: 100vw;
}

.auth-container .auth-background {
    width: 100vw;
    position: fixed;
    height: 100vh;
    left: 0rem;
    top: 0rem;
    background: linear-gradient(180deg, #E0F2FF 12.38%, #FFFFFF 99.97%);
}

.auth-container .auth-background::before {
    position: absolute;
    content: "";
    bottom: -40px;
    overflow: hidden;
    width: 100%;
    left: 0rem;
    height: 600px;
    opacity: 0.7;
    width: 100%;
    z-index: 3;
    background: url('../../Assets/Images/city-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 600px;
}

.auth-container .auth-background::after {
    position: absolute;
    width: 100%;
    left: 0%;
    height: 600px;
    bottom: -140px;
    content: "";
    z-index: 2;
    background: url('../../Assets/Images/city-bg.png');
    opacity: 0.1;
    transform: matrix(1, 0, 0, -1, 0, 0);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 600px;
}

.auth-container .auth-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    z-index: 4;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 4rem 1rem;
    gap: 2rem;
}

.auth-container .auth-wrapper a {
    color: #0084D1;
    font-weight: 500;
    text-decoration: underline;
    font-size: 20px;
    line-height: 20px;
}

.auth-container .auth-wrapper h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: 700;
    color: #272727;
    margin-bottom: 1.5rem;
}

.auth-container .auth-wrapper h2 {
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    color: #272727;
    margin-bottom: 1rem;
}

.auth-container .auth-wrapper p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #272727;
    margin-bottom: 2rem;
}

.auth-container .auth-wrapper .auth-box a {
    font-size: 14px;
}

.auth-container .auth-wrapper section:first-child {
    text-align: center;
}

.auth-container .auth-wrapper section:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-container .auth-wrapper .auth-box {
    background-color: rgba(166, 173, 201, 0.5);
    border-radius: 16px;
    padding: 3rem 2rem;
    width: 100%;
    min-width: 300px;
    max-width: 450px;
    min-height: 400px;
}

.auth-container .auth-wrapper .auth-box form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.login-form-footer,
.signup-form-footer {
    display: flex;
    position: relative;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.signup-form-footer {
    background-color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
}

.signup-form-footer .captcha {
    background: url('../../Assets/Images/captcha.png');
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (min-width:1024px) {

    .auth-container .auth-wrapper {
        display: flex;
        justify-content: space-evenly;
        gap: 5rem;
        padding: 4rem;
    }

    .auth-container .auth-wrapper h1 {
        max-width: 400px;
    }

    .auth-container .auth-wrapper section:first-child {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .auth-container .auth-wrapper .auth-box {
        min-width: 450px;
    }
}

.auth-container .auth-wrapper .auth-box  .auth-content{
    display:flex;
    flex-direction: column;
    text-align: center;
}

.auth-container .auth-wrapper .auth-box  .auth-content p{
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    color: #272727;
    margin-bottom: 2rem;
}

.auth-container .auth-wrapper .auth-box  .auth-content p:nth-child(4){
    font-weight: 450;
}

.auth-container .auth-wrapper .auth-box  .auth-content h2{
    margin-bottom: 2rem;   
    text-align: center;
}

.auth-container .auth-wrapper .auth-box  .auth-content button{
    align-self: center;
    margin-top: -1.5rem;
}