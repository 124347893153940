body,
input,
button,
select,
option,
textarea,
nav,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
p,
q,
div,
aside,
section,
label,
table,
td,
th,
tbody,
thead,
tr {
 font-family: "Poppins", sans-serif;
 padding: 0%;
 margin: 0%;
 border: 0px;
 box-sizing: border-box;
 list-style: none;
 outline-style: none;
 -webkit-tap-highlight-color: transparent;
 text-decoration: none;
 border-collapse: collapse;
}

::-webkit-scrollbar {
 width: 5px;
 height: 5px;
 border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
 background: #e8e9ed;
}

/* Handle */
::-webkit-scrollbar-thumb {
 background: #0084d1;
}

/* react select css */

.css-1s2u09g-control,
.css-1pahdxg-control {
 background-color: transparent !important;
}

.css-1pahdxg-control:hover {
 border-color: rgba(0, 0, 0, 0.15) !important;
}

body {
 width: 100%;
 overflow-x: hidden;
}

button {
 padding: 11px 36px;
 border-radius: 48px;
 background-color: #0084d1;
 font-weight: 500;
 font-size: 14px;
 color: #fff;
 line-height: 20px;
 letter-spacing: 1px;
 width: max-content;
 cursor: pointer;
}

.nav-btn {
 padding: 0rem;
 background-color: transparent;
 color: #272727;
}

.close-btn {
 padding: 0rem;
 background-color: transparent;
 border-radius: 0px;
}

label {
 font-size: 12px;
 line-height: 14px;
 color: #626573;
 border-radius: 32px;
 font-weight: 500;
}

.mandatory-field {
 color: #db3131;
 margin-left: 3px;
}

input,
textarea {
 width: 100%;
 background-color: #fff;
 padding: 20px 24px 16px 24px;
 font-weight: 500;
 font-size: 14px;
 line-height: 16px;
 color: #000000;
 border-radius: 32px;
 transition: 0.1s all linear;
}

textarea {
 border-radius: 16px;
}

input[type="checkbox"] {
 width: auto;
 padding: 0%;
}

input[type="radio"] {
 width: auto;
 padding: 0%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
 -moz-appearance: none;
 -webkit-appearance: none;
}

.text-capitalize {
 text-transform: capitalize !important;
}
.text-capital::first-letter {
 text-transform: capitalize !important;
}
::placeholder {
 color: #626573;
}

.error-message {
 margin: 0px !important;
 padding: 0rem !important;
 font-size: 13px !important;
 line-height: 13px !important;
 font-weight: 400 !important;
 letter-spacing: 0.5px;
 color: #c82333 !important;
}

.input-box {
 position: relative;
}

.input-box label {
 position: absolute;
 left: 24px;
 top: 4px;
 display: none;
}

.input-box button {
 background-color: transparent;
 border-radius: 0px;
 padding: 0px;
 width: auto;
 height: auto;
 display: flex;
 align-items: center;
 justify-content: center;
 position: absolute;
 right: 16px;
 top: 50%;
 transform: translateY(-50%);
}

.input-box svg {
 color: #272727;
}

.input-box:focus-within label,
.input-box.active label {
 display: block;
}

.input-group {
 display: grid;
 grid-template-columns: 1fr;
 gap: 1rem;
}

@media only screen and (min-width: 1024px) {
 .input-group {
  grid-template-columns: 1fr 1fr;
 }
}

.checkbox-container {
 display: block;
 position: relative;
 padding-left: 24px;
 cursor: pointer;
 font-size: 14px;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
}

.checkbox-container input {
 position: absolute;
 opacity: 0;
 cursor: pointer;
 height: 0;
 width: 0;
}

/* Create a custom checkbox */
.checkmark {
 position: absolute;
 top: -2px;
 left: 0;
 height: 18px;
 width: 18px;
 background-color: #fff;
 border-radius: 3px;
}

.checkbox-container.success .checkmark {
 background-color: #eee;
 border: 1px solid #878da4;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
 background-color: #eee;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
 background-color: #0084d1;
}

.checkbox-container.success input:checked ~ .checkmark {
 background-color: #fff;
 border: 0px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
 content: "";
 position: absolute;
 display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
 display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
 left: 6px;
 top: 2px;
 width: 3px;
 height: 10px;
 border: solid white;
 border-width: 0 2px 2px 0;
 -webkit-transform: rotate(45deg);
 -ms-transform: rotate(45deg);
 transform: rotate(45deg);
}

.checkbox-container.success .checkmark:after {
 border-color: #00a860;
 border-width: 0 4px 4px 0;
}

.container {
 margin-top: 80px;
 box-sizing: border-box;
 min-height: calc(100vh - 246px);
}

.container .wrapper {
 padding: 4rem 2rem;
 box-sizing: border-box;
 position: relative;
}

.container .wrapper.wrapper-border-top {
 border-top: 1px solid rgba(233, 235, 240, 0.99);
}

.container .wrapper .wrapper-header {
 position: absolute;
 background-color: #fff;
 font-size: 14px;
 font-weight: 500;
 padding: 8px 16px;
 border: 1px solid rgba(233, 235, 240, 0.99);
 border-radius: 2rem;
 top: -1rem;
 left: 50%;
 transform: translateX(-50%);
 line-height: 14px;
 z-index: 998;
}

.container .wrapper .content-wrapper {
 max-width: 1200px;
 margin: 0rem auto;
 box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
 .container .wrapper {
  padding: 4rem;
 }
}

.avatar {
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #878da4;
 width: 48px;
 height: 48px;
 border-radius: 50%;
 overflow: hidden;
}

.avatar span {
 font-size: 24px;
 font-weight: 600;
}

.avatar img,
.avatars img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.avatars {
 display: flex;
 align-items: center;
 justify-content: center;
 background-color: #878da4;
 width: 32px;
 height: 32px;
 border-radius: 50%;
 overflow: hidden;
}

.avatars span {
 font-size: 16px;
 font-weight: 500;
 color: white;
 text-transform: capitalize !important;
}

/* ADMIN */

.admin-container {
 padding: 3rem 1rem 2rem 1rem;
 background-color: #f0f2f5;
 transition: 0.1s all linear;
 min-height: calc(100vh - 53px);
}

@media only screen and (min-width: 768px) {
 .admin-container {
  padding: 3rem 2rem 2rem 2rem;
 }
}

.admin-container .breadcrumbs p {
 font-weight: 300;
 color: #929fb4;
 font-size: 13px;
}

.admin-container .breadcrumbs p b {
 font-weight: 400;
 color: #344767;
}

.admin-container .breadcrumbs span {
 font-weight: 700;
 font-size: 16px;
 line-height: 22px;
 color: #344767;
}

.admin-container .breadcrumbs {
 position: absolute;
 top: 1rem;
}

@media only screen and (min-width: 1024px) {
 .admin-container {
  padding-left: 298px;
 }
}

.admin-container .content-wrapper .table-view section:first-child {
 overflow: hidden;
 width: 100%;
 height: max-content;
 margin-bottom: 2rem;
 padding: 0rem 3rem 0rem 3rem;
 display: flex;
 flex-direction: column;
 gap: 1rem;
}

.admin-container .content-wrapper .table-view section:first-child .image {
 position: relative;
 border-radius: 8px 8px 0px 0px;
 overflow: hidden;
 height: 400px;
}

.admin-container .content-wrapper .table-view section:first-child .image img {
 object-fit: cover;
 width: 100%;
 height: 100%;
}

.admin-container .content-wrapper .table-view section:first-child .images {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 gap: 1rem;
 position: relative;
 margin: 0rem auto;
 overflow: auto;
 height: max-content;
}

.admin-container .content-wrapper .table-view section:first-child .images li {
 height: 150px;
 max-width: 250px;
 overflow: hidden;
 border-radius: 8px;
}

.admin-container .content-wrapper .table-view section:first-child .images li img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.admin-container .content-wrapper .table-view section:first-child .images span {
 position: absolute;
 padding: 2px 6px;
 background-color: #878da4cc;
 color: #fff;
 display: flex;
 gap: 6px;
 align-items: center;
 border-radius: 4px;
 font-size: 12px;
 bottom: 0px;
 right: 0px;
}

@media only screen and (min-width: 768px) {
 .admin-container .content-wrapper .table-view section:first-child .image {
  height: 400px;
 }
}

@media only screen and (min-width: 1024px) {
 .admin-container .content-wrapper .table-view section:first-child {
  flex-direction: row;
  height: 400px;
 }

 .admin-container .content-wrapper .table-view section:first-child .images {
  grid-template-columns: 1fr;
  width: max-content;
  height: 400px;
 }

 .admin-container .content-wrapper .table-view section:first-child .images li {
  height: 200px;
 }
}

.admin-container .content-wrapper .table-view section:last-child {
 display: flex;
 flex-direction: column;
 gap: 24px;
 padding: 0rem 3rem 3rem 3rem;
}

.admin-container .content-wrapper .table-view section:last-child .modal-box p {
 border-radius: 0rem;
 padding: 8px;
 background: transparent;
 width: 100%;
 font-weight: 500;
 font-size: 14px;
 line-height: 16px;
 color: #000000;
 overflow: auto;
}

.admin-container .content-wrapper .table-view section:last-child .modal-box textarea {
 padding: 8px;
 resize: none;
 background: transparent;
 transition: 0.1s all linear;
 font-weight: 500;
 font-size: 14px;
 line-height: 16px;
 color: #000000;
}

.admin-container .content-wrapper .profile section {
 display: flex;
 flex-direction: column;
}

.admin-container .content-wrapper .profile section:first-child {
 display: flex;
 flex-direction: column;
 gap: 1rem;
}

.admin-container .content-wrapper .profile .table-view h3 {
 font-weight: 500;
 font-size: 20px;
 align-items: left;
 color: #272727;
 padding: 22px 0px 14px 22px;
 margin-top: -4rem;
}

.admin-container .content-wrapper .profile section:last-child {
 width: 100%;
 padding-top: 1rem;
}

.admin-container .content-wrapper .profile section .detail-view {
 background-color: #fff;
 border-radius: 1rem;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 border: 0 solid rgba(0, 0, 0, 0.125);
 position: relative;
 margin-top: -1rem;
 width: 100%;
 height: max-content;
}

.admin-container .content-wrapper .profile section .detail-view h3 {
 font-weight: 500;
 font-size: 20px;
 align-items: left;
 color: #272727;
 padding: 22px 0px 14px 22px;
 /* margin-top: -4rem; */
}

@media only screen and (min-width: 1024px) {
 .admin-container .content-wrapper .profile section {
  flex-direction: row;
 }
}

.admin-container .content-wrapper .table-view .admin-pagination {
 padding: 2rem 20px 20px 20px;
 display: flex;
 align-items: center;
 justify-content: flex-end;
 gap: 8px;
}

.admin-container .content-wrapper .table-view .admin-pagination button {
 width: 24px;
 height: 24px;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 0px;
 background-color: #63a4ff;
}

.admin-container .content-wrapper .table-view .admin-pagination button.disabled {
 background-color: #979797;
}

.admin-container .content-wrapper .table-view .admin-pagination span {
 font-size: 13px;
 color: #626573;
}

.admin-container .content-wrapper .table-view .table-filters {
 padding: 0rem 20px 32px 20px;
 display: flex;
 gap: 2rem;
 align-items: center;
 justify-content: flex-end;
 flex-wrap: wrap;
}

.admin-container .content-wrapper .table-view .table-filters .dropdown {
 display: flex;
 gap: 4px;
 align-items: center;
 flex-wrap: wrap;
}

.admin-container .content-wrapper .table-view .table-filters label {
 font-size: 14px;
 font-weight: 400;
}

.admin-container .content-wrapper .table-view .table-filters .search {
 display: flex;
 align-items: center;
 max-width: 300px;
 width: 100%;
 gap: 0rem;
 border: 2px solid #63a4ff;
 border-radius: 5px;
}

.admin-container .content-wrapper .table-view .table-filters .search input {
 padding: 5px 8px;
 width: 100%;
 border: 0px;
 font-size: 12px;
}

.admin-container .content-wrapper .table-view .table-filters .search button {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 40px;
 background-color: #63a4ff;
 border-radius: 0px;
}

.admin-container .content-wrapper .table-view .table-filters .dropdown select {
 border: 2px solid #63a4ff;
 padding: 4px 8px;
 border-radius: 5px;
 font-size: 12px;
 width: max-content;
}

.admin-container .content-wrapper .table-view {
 background-color: #fff;
 border-radius: 1rem;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 border: 0 solid rgba(0, 0, 0, 0.125);
 position: relative;
 padding-top: 6rem;
 margin-top: 2rem;
}

.admin-container .content-wrapper .table-view .header {
 background-color: #63a4ff;
 border-radius: 12px;
 padding: 1rem;
 box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(99, 164, 255, 0.5);
 width: calc(100% - 2rem);
 display: flex;
 gap: 1rem;
 justify-content: space-between;
 align-items: center;
 flex-wrap: wrap;
 position: absolute;
 left: 1rem;
 top: -37px;
}

.admin-container .content-wrapper .table-view .header button {
 box-shadow: none;
}

.admin-container .content-wrapper .table-view .header h1 {
 font-size: 1rem;
 font-weight: 600;
 line-height: 1.625rem;
 color: #fff;
}

.admin-container .content-wrapper .table-view .table,
.admin-container .content-wrapper .profile section .detail-view .table,
.modal-container .modal .content .table {
 overflow-x: auto;
}

.admin-container .content-wrapper .table-view .table table,
.admin-container .content-wrapper .profile section .detail-view .table table,
.modal-container .modal .content .table table {
 align-items: center;
 width: 100%;
}

.admin-container .content-wrapper .table-view .table thead th,
.admin-container .content-wrapper .profile section .detail-view .table thead th,
.modal-container .modal .content .table thead th {
 padding: 12px 24px;
 text-align: left;
 border-bottom: 1px solid #f0f2f5;
 white-space: nowrap;
 color: #7b809a;
 opacity: 0.7;
 font-size: 12px;
 font-weight: 500;
}

.admin-container .content-wrapper .table-view .table tbody td,
.admin-container .content-wrapper .profile section .detail-view .table tbody td,
.modal-container .modal .content .table tbody td {
 text-align: left;
 padding: 12px 24px;
 border-bottom: 1px solid #f0f2f5;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.admin-container .content-wrapper .table-view .table tbody .admin-table-desc {
 max-width: 300px;
 white-space: wrap;
}

.admin-container .content-wrapper .table-view .table tbody td img,
.modal-container .modal .content .table tbody td img {
 border-radius: 8px;
 height: 36px;
 width: 36px;
 object-fit: cover;
}

.admin-container .content-wrapper .table-view .table tbody tr:last-child td,
.admin-container .content-wrapper .profile section .detail-view .table tbody tr:last-child td,
.modal-container .modal .content .table tbody tr:last-child td {
 border-bottom: 0px;
}

.admin-container .content-wrapper .table-view .table tbody td,
.admin-container .content-wrapper .profile section .detail-view tbody td,
.modal-container .modal .content .table tbody td {
 color: #7b809a;
 font-weight: 600;
 font-size: 12px;
 line-height: 20px;
}

.admin-container .content-wrapper .table-view .table tbody .head,
.admin-container .content-wrapper .profile section .detail-view .table tbody .head,
.modal-container .modal .content .table tbody .head {
 font-size: 0.875rem;
 color: #344767;
}

.admin-container .content-wrapper .table-view .table .table-actions,
.admin-container .content-wrapper .profile section .detail-view .table .table-actions,
.modal-container .modal .content .table .table-actions {
 display: flex;
 width: 100%;
 gap: 8px;
 align-items: center;
}

.admin-container .content-wrapper .table-view button,
.modal-container .modal button {
 padding: 5px 10px;
 border-radius: 3px;
 font-size: 12px;
 font-weight: 500;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.admin-container .content-wrapper .table-view .table span,
.modal-container .modal .table span,
.table-container .view .tables span {
 padding: 8px 10px;
 border-radius: 3px;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.admin-container .content-wrapper .table-view .table .active,
.table-container .view .tables .active,
.modal-container .modal .table .active {
 background-color: #e3fcfa;
 color: #195832;
}

.admin-container .content-wrapper .table-view .table .deactive,
.modal-container .modal .table .deactive {
 background-color: #f3f3f3;
}

.admin-container .content-wrapper .table-view .add-btn {
 padding: 8px 10px;
 color: #212529;
 background-color: #f8f9fa;
 font-size: 13px;
}

.admin-container .content-wrapper .table-view .add-btn:hover {
 background-color: #e2e6ea;
}

.admin-container .content-wrapper .table-view .deactivate-btn,
.modal-container .modal .table .deactivate-btn {
 /* background-color: #6c757d; */
 background: linear-gradient(195deg, #a7abb4, #84898f);
}

.admin-container .content-wrapper .table-view .edit-btn {
 background: linear-gradient(195deg, #6ad8e9, #28c2da);
}

.admin-container .content-wrapper .table-view .activate-btn,
.modal-container .modal .table .activate-btn {
 background: linear-gradient(195deg, #7bdf80, #54ca5a);
}

.modal-container {
 position: fixed;
 background-color: rgba(0, 0, 0, 0.5);
 top: 0rem;
 left: 0rem;
 height: 100vh;
 width: 100vw;
 z-index: 9999;
 display: flex;
 justify-content: center;
 align-items: center;
}

.modal-container .modal {
 margin-top: 1.5rem;
 width: calc(100% - 2rem);
 max-width: 700px;
 max-height: calc(100vh - 5rem);
 background-color: rgba(255, 255, 255, 0.9);
 padding: 1rem;
 border-radius: 1rem;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 position: relative;
 padding-top: 4rem;
}

.modal-container .modal .header {
 background-color: #63a4ff;
 border-radius: 12px;
 padding: 1rem;
 box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(99, 164, 255, 0.5);
 width: calc(100% - 2rem);
 display: flex;
 gap: 1rem;
 justify-content: space-between;
 align-items: center;
 flex-wrap: wrap;
 position: absolute;
 left: 1rem;
 top: -28px;
}

.modal-container .modal .header h3 {
 color: #fff;
 line-height: 1.625rem;
 font-size: 16px;
 font-weight: 600;
}

.modal-container .modal .header button {
 padding: 0rem;
 background-color: transparent;
 border-radius: 0px;
 box-shadow: none;
}

.modal-container .modal .content {
 max-height: calc(100vh - 10rem);
 overflow-y: auto;
}

.modal-container .modal .content form,
.admin-container .profile .profile-edit form {
 display: flex;
 flex-direction: column;
 gap: 24px;
 padding: 8px;
}

.modal-container .modal .content::-webkit-scrollbar {
 display: none;
}

.modal-container .modal .content form .modal-input-grid,
.admin-container .content-wrapper .table-view .modal-grid,
.admin-container .profile .profile-edit .modal-input-grid {
 display: grid;
 grid-template-columns: 1fr;
 gap: 24px;
}

.admin-container .profile .info,
.admin-container .profile .profile-edit {
 background-color: #fff;
}

@media only screen and (min-width: 768px) {
 .modal-container .modal .content form .modal-input-grid,
 .admin-container .content-wrapper .table-view .modal-grid,
 .admin-container .profile .profile-edit .modal-input-grid {
  grid-template-columns: 1fr 1fr;
 }
}

.modal-container .modal .content form .modal-input-box,
.admin-container .content-wrapper .table-view .content .modal-box,
.admin-container .profile .profile-edit .modal-input-box {
 display: flex;
 flex-direction: column;
 gap: 2px;
}

.modal-container .modal .content form .modal-input-box input,
.modal-container .modal .content form .modal-input-box select,
.admin-container .profile .profile-edit .modal-input-box input,
.admin-container .profile .profile-edit .modal-input-box select {
 border-radius: 0rem;
 padding: 8px;
 background: transparent;
 border-bottom: 1px solid rgba(0, 0, 0, 0.15);
 transition: 0.1s all linear;
}

.modal-container .modal .content form .modal-input-box select {
 padding: 6px 8px;
}

.modal-container .modal .content form .modal-input-box textarea {
 border-radius: 3px;
 padding: 8px;
 resize: none;
 background: transparent;
 border: 1px solid rgba(0, 0, 0, 0.15);
 transition: 0.1s all linear;
}

.modal-container .modal .content form .modal-input-box .info {
 font-size: 0.65rem;
 color: #63a4ff;
 letter-spacing: 0.5px;
}

.modal-container .modal .content form .modal-input-box .info b {
 font-size: 0.75rem;
 color: #e64656;
}

.modal-container .modal .content form .modal-input-box input[type="radio"] {
 margin-top: 1rem;
}

.modal-container .modal .content form .modal-input-box ul {
 display: flex;
 flex-direction: row;
 gap: 2rem;
}

.modal-container .modal .content form .modal-input-box:focus-within label,
.admin-container .profile .profile-edit .modal-input-box label {
 color: #0084d1;
}

.modal-container .modal .content form .modal-input-box:focus-within input,
.admin-container .profile .profile-edit .modal-input-box input,
.admin-container .profile .profile-edit .modal-input-box select,
.modal-container .modal .content form .modal-input-box select {
 border-bottom: 2px solid #0084d1;
}

.modal-container .modal .content form .modal-input-box:focus-within textarea {
 border: 2px solid #0084d1;
}

.modal-container .modal .content form .action-box,
.admin-container .profile form .action-box {
 display: flex;
 align-items: center;
 gap: 16px;
 justify-content: flex-end;
}

.modal-container .modal .content form .action-box button,
.admin-container .profile form .action-box button,
.admin-container .profile .info button {
 padding: 5px 10px;
 border-radius: 3px;
 font-size: 12px;
 font-weight: 500;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.modal-container .modal .content form .action-box .create {
 background: linear-gradient(195deg, #66bb6a, #43a047);
 padding: 5px 10px;
}

.modal-container .modal .content form .images-box {
 border: 2px dashed rgb(0 0 0 / 14%);
 padding: 1rem;
 border-radius: 1rem;
 display: flex;
 flex-direction: column;
 gap: 1rem;
 align-items: center;
 justify-content: center;
}

.modal-container .modal .content form .images-box ul {
 display: grid;
 gap: 1rem;
 grid-template-columns: 1fr 1fr;
}

.modal-container .modal .content form .images-box ul li {
 height: 150px;
 position: relative;
}

.modal-container .modal .content form .images-box ul li button {
 position: absolute;
 background-color: #db5360;
 color: #fff;
 z-index: 1;
 right: -8px;
 top: -8px;
 padding: 0rem;
 border-radius: 50%;
 width: 24px;
 height: 24px;
 display: flex;
 align-items: center;
 justify-content: center;
}

@media only screen and (min-width: 551px) {
 .modal-container .modal .content form .images-box ul {
  grid-template-columns: 1fr 1fr 1fr;
 }
}

@media only screen and (min-width: 768px) {
 .modal-container .modal .content form .images-box ul {
  grid-template-columns: 1fr 1fr 1fr 1fr;
 }
}

.modal-container .modal .content form .images-box ul li img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.modal-container .modal .content form .images-box .images-box-actions input,
.table-container .view .file .file-box-actions input {
 display: none;
}

.modal-container .modal .content form .images-box .images-box-actions label {
 text-align: center;
 letter-spacing: 0px;
 padding: 8px 10px;
 border-radius: 3px;
 background-color: #344767;
 line-height: 14px;
 color: #fff;
 font-size: 12px;
 font-weight: 500;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 cursor: pointer;
}

.modal-container .modal .content form .image-box,
.admin-container .profile form .image-box {
 display: flex;
 flex-wrap: wrap;
 gap: 8px;
}

.modal-container .modal .content form .image-box .image-wrapper,
.admin-container .profile form .image-box .image-wrapper {
 width: 150px;
 height: 150px;
 border: 1px solid rgba(0, 0, 0, 0.15);
}

.modal-container .modal .content form .image-box .image-wrapper img,
.admin-container .profile form .image-box .image-wrapper img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.modal-container .modal .content form .image-box label,
.modal-container .modal .content form .image-box .remove,
.admin-container .profile form .image-box label {
 max-width: 150px;
 text-align: center;
 letter-spacing: 0px;
 padding: 8px 10px;
 border-radius: 3px;
 background-color: #344767;
 line-height: 14px;
 color: #fff;
 font-size: 12px;
 font-weight: 500;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 cursor: pointer;
}

.modal-container .modal .content form .image-box .remove {
 background: linear-gradient(195deg, #c82333, #cc0b1e);
}

.modal-container .modal .content form .image-box .image-box-actions {
 display: flex;
 flex-direction: column;
 gap: 8px;
}
.modal-container .modal .content form > .images-box-actions input {
 width: 100%;
 background-color: inherit;
 padding: 20px 24px 16px 24px;
 font-weight: 500;
 font-size: 14px;
 border: 2px dashed #cccccc;
 line-height: 16px;
 color: #000000;
 border-radius: 16px;
 transition: 0.1s all linear;
}
.modal-container .modal .content form .image-box input,
.admin-container .profile form .image-box input {
 display: none;
}

.admin-auth-container {
 width: 100vw;
 padding: 2rem;
 min-height: 100vh;
 display: flex;
 align-items: center;
 justify-content: center;
 background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
 position: relative;
 box-sizing: border-box;
 overflow: hidden;
}

.admin-auth-container::before {
 position: absolute;
 content: "";
 bottom: -40px;
 overflow: hidden;
 width: 100%;
 left: 0rem;
 height: 600px;
 opacity: 0.7;
 width: 100%;
 z-index: 3;
 background: url("./Assets/Images/city-bg.png");
 background-repeat: no-repeat;
 background-position: center;
 background-size: 100% 600px;
}

.admin-auth-container::after {
 position: absolute;
 width: 100%;
 left: 0%;
 height: 600px;
 bottom: -140px;
 content: "";
 z-index: 2;
 background: url("./Assets/Images/city-bg.png");
 opacity: 0.1;
 transform: matrix(1, 0, 0, -1, 0, 0);
 background-repeat: no-repeat;
 background-position: center;
 background-size: 100% 600px;
}

.admin-auth-container .auth-box {
 background-color: rgba(166, 173, 201, 0.5);
 max-width: 450px;
 width: 100%;
 padding: 3rem 2rem;
 position: relative;
 z-index: 4;
 border-radius: 1rem;
}

.admin-auth-container .auth-box form {
 display: grid;
 grid-template-columns: 1fr;
 gap: 1rem;
}

.admin-auth-container p {
 font-size: 32px;
 line-height: 32px;
 font-weight: 500;
 color: #272727;
 margin-bottom: 2rem;
}

.admin-auth-container a {
 text-align: right;
 text-decoration: underline;
 color: #0084d1;
 font-size: 14px;
 font-weight: 500;
}

.admin-auth-container .auth-box .resend-span {
 text-align: center;
}

.admin-auth-container .auth-box form .resend-btn {
 margin: 0rem auto;
 padding: 0rem;
 color: #0084d1;
 text-decoration: underline;
 background-color: transparent;
 border-radius: 0px;
}

.admin-auth-container p:nth-child(2) {
 font-size: 16px;
 line-height: 16px;
}

.admin-auth-container .auth-box .login-actions {
 display: flex;
 position: relative;
 align-items: center;
 gap: 1rem;
 justify-content: space-between;
}

.admin-container .profile {
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.admin-container .profile .info {
 height: max-content;
 display: flex;
 flex-direction: column;
 gap: 10px;
 justify-content: center;
 align-items: center;
 width: 100%;
 padding: 1rem;
 border-radius: 8px;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.admin-container .profile .profile-edit {
 padding: 1rem;
 border-radius: 8px;
 box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
}

@media only screen and (min-width: 768px) {
 .admin-container .profile {
  flex-direction: row;
 }

 .admin-container .profile .info {
  max-width: 300px;
 }

 .admin-container .profile .profile-edit {
  width: 100%;
 }
}

.admin-container .profile .info div {
 width: 150px;
 height: 150px;
 border-radius: 50%;
 overflow: hidden;
 background-color: #f5f7f8;
}

.admin-container .profile .info h5 {
 font-size: 20px;
 font-weight: 500;
 color: #000;
}

.admin-container .profile .info p {
 font-size: 14px;
 color: #585858;
 font-weight: 500;
}

.admin-container .profile .info div img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

/* USER PROFILE */

.container .user-wrapper {
 display: flex;
 flex-direction: column;
 position: relative;
 z-index: 4;
 width: 100%;
 min-height: 100vh;
 box-sizing: border-box;
 padding: 1rem 0rem;
 gap: 1.5rem;
 align-items: center;
 background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
}

.user-wrapper h2 {
 font-weight: 500;
 font-size: 24px;
 line-height: 36px;
 color: #272727;
 align-content: left;
 width: 100%;
 padding-left: 3rem;
}

.user-wrapper .user-content {
 display: flex;
 flex-direction: column;
 gap: 20px;
 padding: 0rem 3rem;
 width: 100%;
}

.user-wrapper .user-content .user-info {
 height: max-content;
 display: flex;
 flex-direction: column;
 gap: 10px;
 justify-content: center;
 align-items: center;
 width: 100%;
 padding: 3rem;
 border-radius: 8px;
 background-color: #ffffff;
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.user-wrapper .user-content .user-info div {
 width: 150px;
 height: 150px;
 border-radius: 50%;
 box-sizing: border-box;
 border: 2px solid #ffffff;
 box-shadow: 0px 4px 20px rgba(231, 230, 230, 0.25);
 overflow: hidden;
 background-color: #f5f7f8;
}

.user-wrapper .user-content .user-info div img {
 width: 100%;
 height: 100%;
 object-fit: cover;
}

.user-wrapper .user-content .user-info h5 {
 font-size: 20px;
 font-weight: 500;
 color: #000;
}

.user-wrapper .user-content .user-info p {
 font-size: 14px;
 color: #585858;
 font-weight: 500;
}

.user-wrapper .user-content .user-info a {
 color: #0084d1;
 text-decoration: underline;
 text-decoration-color: #0084d1;
}

.user-wrapper .user-content .user-profile-edit {
 border-radius: 8px;
 box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
 display: flex;
 flex-direction: column;
 gap: 20px;
 overflow: auto;
 width: 100%;
}

@media only screen and (min-width: 1024px) {
 .container .user-wrapper {
  padding: 3rem;
 }

 .user-wrapper .user-content {
  flex-direction: row;
  width: 100%;
 }

 .user-wrapper .user-content .user-info {
  width: 300px;
 }
}

.table-container .view {
 background-color: #fff;
 position: relative;
 border-radius: 8px;
 gap: 0.5rem;
 display: flex;
 flex-direction: column;
 padding-bottom: 1rem;
}

.table-container .view h1 {
 font-weight: 500;
 font-size: 20px;
 align-items: left;
 color: #272727;
 padding: 22px 0px 14px 22px;
}

.table-container view .file-content {
 padding-left: 3rem;
}

.table-container .view .file {
 border: 1px dashed #adadad;
 padding: 2rem 3rem 3rem 3rem;
 border-radius: 16px;
 display: flex;
 flex-direction: column;
 gap: 1rem;
 align-items: center;
 justify-content: center;
 margin-left: 22px;
 margin-right: 60px;
}

.table-container .view .file .file-box-actions label {
 text-align: center;
 line-height: 14px;
 color: #000000;
 font-size: 14px;
 font-weight: 500;
 gap: 0rem;
 cursor: pointer;
}

.table-container .view .file .file-box-actions label span {
 color: #0084d1;
 padding: 0rem;
}

.table-container .view .file .file-box {
 display: flex;
 flex-direction: column;
 gap: 1rem;
 align-items: center;
}

.table-container .view .file .file-box div {
 display: flex;
 flex-direction: row;
 gap: 1rem;
}

.table-container .view .file .file-box button {
 align-items: center;
 justify-content: center;
}

.table-container .view span {
 font-weight: 500;
 font-size: 12px;
 line-height: 18px;
 color: #ff5c83;
 padding-left: 2rem;
}

.table-container .view .tables {
 overflow: auto;
}

.table-container .view .tables table {
 align-items: center;
 width: 100%;
}

.table-container .view .tables thead {
 box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
 border-radius: 8px 8px 0px 0px;
}

.table-container .view .tables thead tr th {
 padding: 12px 24px;
 text-align: left;
 border-bottom: 1px solid #f0f2f5;
 border-top: 1px solid #f0f2f5;
 color: #626573;
 font-weight: 500;
 font-size: 14px;
}

.table-container .view .tables tbody td {
 text-align: left;
 padding: 12px 24px;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.table-container .view .tables tbody td:first-child {
 color: #0084d1;
 display: flex;
 gap: 1rem;
}

.table-container .view .table-action a {
 display: flex;
 justify-content: flex-end;
 margin-right: 30px;
 color: #0084d1;
 text-decoration: underline;
 text-decoration-color: #0084d1;
}

.table-container .view .tables .deactive {
 background-color: #f3f3f3;
 color: #000000;
}

/* phase 2 loadingcss */

.loading_page {
 width: 100%;
 padding: 2rem;
 display: flex;
 justify-content: center;
 align-items: center;
}
