.modal_container {
 position: fixed;
 background-color: rgba(0, 0, 0, 0.5);
 top: 0rem;
 left: 0rem;
 height: 100vh;
 width: 100vw;
 z-index: 9999;
 display: flex;
 justify-content: center;
 align-items: center;
}
.modal_popup {
 margin-top: 1.5rem;
 width: calc(100% - 2rem);
 max-width: 544px;
 max-height: calc(100vh - 5rem);
 background-color: #fff;
 /* border-radius: 1rem; */
 box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
 position: relative;
}
.modal_content {
 height: max-content;
 display: flex;
 flex-direction: column;
 gap: 2rem;
 justify-content: center;
 align-items: center;
 width: 100%;
 padding: 3rem 1rem;
 border-radius: 8px;
 background-color: #ffffff;
}
.primary_img_div {
 width: 100%;
 height: 80%;
 min-height: 100%;
 height: 425px;
 position: relative;
}
.cancel_icon {
 position: absolute;
 right: 1rem;
 top: 1rem;
 height: 1.5rem;
 width: 1.5rem;
 cursor: pointer;
}
.seconday_img_box {
 width: 100%;
 height: 120px;
 padding: 1rem 0rem;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 column-gap: 0.5rem;
}
.seconday_img_div {
 width: 20%;
 height: 100%;
}.carousel_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.plans_actions {
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 1rem;
}
.plans_actions >button {
 padding: 0rem;
 width: 60px;
 height: 42px;
 border-radius: 2rem;
 background-color: #fff;
}

.plans_actions button svg {
 color: #979797;
}

.plans_actions .active {
 /* box-shadow: 0px 4px 20px 0px #adadad4d; */
}

.plans_actions .active svg {
 color: #0084d1;
}
