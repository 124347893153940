.container .wrapper.landing-hero {
  background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
  overflow: hidden;
  padding-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .container .wrapper.landing-hero {
    padding-bottom: 6rem;
  }
}

.landing-hero::before {
  /* min-width: 1400px; */
  position: absolute;
  content: "";
  top: 350px;
  overflow: hidden;
  width: 100%;
  left: 0rem;
  opacity: 0.7;
  height: 600px;
  width: 100%;
  z-index: 3;
  background: url("../../Assets/Images/city-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

.landing-hero::after {
  /* min-width: 1400px; */
  position: absolute;
  width: 100%;
  left: 0%;
  height: 600px;
  top: 530px;
  content: "";
  z-index: 2;
  background: url("../../Assets/Images/city-bg.png");
  opacity: 0.1;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

@media only screen and (min-width: 768px) {
  .landing-hero::after {
    top: 280px;
  }

  .landing-hero::before {
    top: 100px;
  }
}

.landing-hero .content-wrapper {
  position: relative;
  z-index: 10;
}

.landing-hero .content-wrapper p {
  text-align: center;
}

.landing-hero-header-wrapper {
  margin: 0rem auto;
}

.landing-hero-header {
  display: flex;
  align-items: center;
  gap: 0px 10px;
  margin: 0rem auto;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: center;
}

.landing-hero-header h1 {
  font-weight: 700;
  font-size: 44px;
  color: #272727;
  text-align: center;
}

.landing-hero-header ul {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  text-align: center;
}

.landing-hero-header ul li {
  position: relative;
  height: 90px;
  top: 0;
  animation: slide 4.5s steps(2) infinite;
}

.landing-hero-header ul li::before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 2rem;
  height: 15px;
  background-color: #fff;
}

@keyframes slide {
  100% {
    top: -180px;
  }
}

.landing-hero-header ul li:first-child h1 {
  color: #d09b6b;
  position: relative;
}

.landing-hero-header ul li:nth-child(2) h1 {
  color: #8227c3;
  position: relative;
}

/* .landing-hero-header ul li:nth-child(3) h1 {
    color: #57BAC4;
    position: relative;
} */

.landing-hero p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2rem;
}

.landing-hero-enquire {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.landing-hero-enquire input {
  max-width: 375px;
  height: 42px;
  padding: 0rem 32px;
}

.landing-hero-enquire button {
  height: 42px;
  padding: 0rem 24px;
}

.landing-hero-services {
  margin-top: 8rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.landing-hero-services li {
  height: 350px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px #9e9e9e33;
  max-width: 350px;
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.landing-hero-services li h3 {
  font-size: 24px;
  line-height: 20px;
}

.landing-hero-services p {
  color: #585858;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px;
}

.landing-hero-services button {
  padding: 8px 24px;
  margin-top: auto;
}

.landing-hero-services li .icon {
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.landing-hero-services li:nth-child(1) .icon {
  background: url("../../Assets/Images/Realestate.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 80px;
}

.landing-hero-services li:nth-child(2) .icon {
  background: url("../../Assets/Images/Financialservices.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 80px;
}

.landing-hero-services li:nth-child(3) .icon {
  background: url("../../Assets/Images/Medicalservices.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 80px;
}
/* .landing-hero-services li h3 {
  color: #0084d1;
} */
.landing-hero-services li:nth-child(1) h3 {
  color: #ab4618;
}

.landing-hero-services li:nth-child(2) h3 {
  color: #6716a1;
}

.landing-hero-services li:nth-child(3) h3 {
  color: #066d74;
}

.landing-hero-services li:nth-child(1) button {
  background-color: #d09b6b;
}
.landing-hero-services li:nth-child(2) button {
  background-color: #a768d5;
}
/* 
.landing-hero-services li:nth-child(3) button {
  background-color: #57bac4;
} */

@media only screen and (min-width: 768px) {
  .landing-hero-enquire {
    flex-direction: row;
    gap: 0rem;
  }

  .landing-hero-enquire input {
    border-radius: 32px 0px 0px 32px;
  }

  .landing-hero-enquire button {
    border-radius: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .landing-hero-header {
    width: max-content;
  }
}

.landing-partners {
  background: linear-gradient(
    179.54deg,
    rgba(87, 186, 196, 0.04) 0.4%,
    rgba(219, 235, 249, 0) 123.51%,
    rgba(219, 235, 249, 0) 123.51%
  );
}

.landing-partners .content-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0rem auto;
}

.landing-partners .content-wrapper ul li {
  width: 250px;
  height: 100px;
}

.landing-partners .content-wrapper ul li:first-child {
  background: url("../../Assets/Images/ICICI.svg");
  background-position: center;
  background-repeat: no-repeat;
}

/* .landing-partners .content-wrapper ul li:nth-child(2) {
  background: url("../../Assets/Images/sbi.svg");
  background-position: center;
  background-repeat: no-repeat;
} */

/* .landing-partners .content-wrapper ul li:nth-child(3) {
  background: url("../../Assets/Images/payoneer.svg");
  background-position: center;
  background-repeat: no-repeat;
} */

/* .landing-partners .content-wrapper ul li:nth-child(4) {
  background: url("../../Assets/Images/pingpong.svg");
  background-position: center;
  background-repeat: no-repeat;
} */

.landing-partners .content-wrapper ul li:nth-child(2) {
  background: url("../../Assets/Images/parvasi.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.landing-partners .content-wrapper ul li:nth-child(3) {
  background: url("../../Assets/Images/hga.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.landing-partners .content-wrapper ul li:last-child {
  background: url("../../Assets/Images/parshotam.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100px;
}

.landing-testimonials {
  background: linear-gradient(
    179.54deg,
    rgba(87, 186, 196, 0.04) 0.4%,
    rgba(219, 235, 249, 0) 123.51%,
    rgba(219, 235, 249, 0) 123.51%
  );
}

.landing-testimonials .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;
}

.testimonial-card {
  margin-top: 4rem;
  max-width: 568px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px #9e9e9e33;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}

.testimonial-card p {
  /* font-size: 24px; */
  font-size: 20px;
  color: #808080;
  font-weight: 500;
}

.testimonial-card svg {
  color: #dc9a7c;
  position: absolute;
  z-index: -1;
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.testimonial-users {
  flex: 1;
  width: 100%;
}

.testimonial-users ul {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  overflow-x: auto;
}

.testimonial-users ul li button {
  background-color: transparent;
  padding: 0rem;
  border-radius: 50%;
  transition: 0.3s all ease-in-out;
  overflow: hidden;
  height: 50px;
  width: 50px;
}

.testimonial-users ul li button img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  opacity: 0.5;
}

.testimonial-users ul .active button {
  width: 80px;
  height: 80px;
}

.testimonial-users ul .active button img {
  width: 80px;
  height: 80px;
  opacity: 1;
}

.testimonials-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
}

.testimonials-actions button {
  padding: 0rem;
  width: 60px;
  height: 42px;
  border-radius: 2rem;
  background-color: #fff;
}

.testimonials-actions button svg {
  color: #979797;
}

.testimonials-actions .active {
  box-shadow: 0px 4px 20px 0px #adadad4d;
}

.testimonials-actions .active svg {
  color: #0084d1;
}

.landing-services .content-wrapper {
  background-color: #f9fafb;
  border-radius: 100px 8px 8px 8px;
  padding: 2rem 2rem 4rem 2rem;
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr;
}

.landing-services .content-wrapper section:first-child h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 67px;
  margin-bottom: 2rem;
}

.landing-services .content-wrapper section:first-child p {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: #878da4;
  margin-bottom: 2rem;
}

.landing-services .content-wrapper section:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-services .content-wrapper section:last-child ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.landing-services .content-wrapper section:last-child ul li {
  box-shadow: 0px 20px 40px rgba(15, 28, 73, 0.04);
  border: 1rem solid #fff;
  width: 270px;
  height: 270px;
  background-color: #f9fafb;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  box-shadow: 0px 20px 40px rgba(15, 28, 73, 0.04);
}

.landing-services .content-wrapper section:last-child ul li:first-child {
  border-radius: 8px 100px;
}

.landing-services .content-wrapper section:last-child ul li:nth-child(2) {
  border-radius: 100px 8px;
}

.landing-services .content-wrapper section:last-child ul li:nth-child(3) {
  border-radius: 100px 8px;
}

.landing-services .content-wrapper section:last-child ul li:last-child {
  border-radius: 8px 100px;
}

.landing-services .content-wrapper section:last-child ul li span {
  font-size: 48px;
  font-weight: 600;
  color: #fec62e;
}

.landing-services .content-wrapper section:last-child ul li h4 {
  font-size: 24px;
  font-weight: 500;
  color: #0f1c49;
}

.container .wrapper.landing-services {
  padding: 2rem 2rem 0rem 2rem;
}

@media only screen and (min-width: 768px) {
  .landing-services .content-wrapper {
    padding: 6rem 4rem;
  }

  .landing-services .content-wrapper section:last-child ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .container .wrapper.landing-services {
    padding: 12rem 4rem 0rem 4rem;
  }

  .landing-services .content-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .landing-services .content-wrapper section:last-child {
    margin-top: -300px;
  }
}

.container .wrapper.landing-works {
  padding-top: 6rem;
}

.landing-works {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) -9.97%,
    #ecf0f7 100%
  );
}

.landing-works .content-wrapper {
  display: flex;
  gap: 4rem;
}

/* .landing-works section:first-child {
  grid-template-columns: 1fr;
  display: none;
} */

/* .landing-works section:first-child div:first-child {
  background: url("../../Assets/Images/Illustration1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
}

.landing-works section:first-child div:nth-child(2) {
  background: url("../../Assets/Images/Illustration2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
}

.landing-works section:first-child div:last-child {
  background: url("../../Assets/Images/Illustration3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
} */

.landing-works section:first-child div {
  width: 400px;
  height: 200px;
}

.landing-works .content-wrapper section:first-child ul {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.landing-works .content-wrapper section:first-child ul li {
  /* height: 350px; */
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px #9e9e9e33;
  max-width: 350px;
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.landing-works .content-wrapper section:first-child ul li .icon {
  overflow: hidden;
  width: 180px;
  height: 180px;
}
.landing-works .content-wrapper section:first-child ul li h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  color: #272727;
}
.landing-works .content-wrapper section:first-child ul li p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #585858;
}
.landing-works .content-wrapper section:first-child ul li:first-child .icon {
  background: url("../../Assets/Images/realestate3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 170px;
}
.landing-works .content-wrapper section:first-child ul li:first-child h3 {
  color: #ab4618;
}
.landing-works .content-wrapper section:first-child ul li:first-child button {
  background-color: #d09b6b;
}
.landing-works .content-wrapper section:first-child ul li:last-child .icon {
  background: url("../../Assets/Images/finance3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 170px;
}
.landing-works .content-wrapper section:first-child ul li:last-child h3 {
  color: #6716a1;
}
.landing-works .content-wrapper section:first-child ul li:last-child button {
  background-color: #a768d5;
}

/* .landing-works .content-wrapper section:last-child ul li:last-child {
  border: 0px;
} */

.landing-works .content-wrapper section:last-child ul li svg {
  position: absolute;
  left: -11px;
  background: rgba(124, 213, 24, 0.2);
  color: #7cd518;
  border: 2px solid #7cd518;
  top: -11px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.landing-works .content-wrapper section:last-child ul li div {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
  gap: 12px;
}

.landing-works .content-wrapper section:last-child ul li div span {
  font-size: 40px;
  color: #626573;
  font-weight: 500;
  line-height: 40px;
}

.landing-works .content-wrapper section:last-child ul li div h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #272727;
}

.landing-works .content-wrapper section:last-child ul li div p {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
}

.landing-works .content-wrapper section:last-child ul li div p b {
  font-weight: 600;
}

.landing-works .content-wrapper section:last-child ul li div progress {
  border-radius: 7px;
  width: 100%;
  height: 6px;
}

.landing-works
  .content-wrapper
  section:last-child
  ul
  li
  div
  progress::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 7px;
}

.landing-works
  .content-wrapper
  section:last-child
  ul
  li
  div
  progress::-webkit-progress-value {
  background-color: #1aa2f8;
  border-radius: 7px;
}

@media only screen and (min-width: 768px) {
  .landing-works .content-wrapper section:first-child {
    display: grid;
    margin-left: 20%;
  }
}

.landing-offer {
  background: linear-gradient(
    180.07deg,
    rgba(243, 250, 255, 0.72) 0.06%,
    rgba(219, 235, 249, 0) 99.94%,
    rgba(239, 233, 253, 0.07) 99.94%
  );
}

.landing-offer .content-wrapper h2 {
  font-size: 32px;
  line-height: 32px;
  color: #000;
  margin-bottom: 4rem;
  font-weight: 500;
}

.landing-offer .content-wrapper h2 span {
  color: #0084d1;
}

.landing-offer .content-wrapper .offer-cards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-offer .content-wrapper ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.landing-offer .content-wrapper ul li div {
  width: 50px;
  height: 50px;
  margin-bottom: 8px;
}

.landing-offer .content-wrapper ul li:first-child div {
  background: url("../../Assets/Images/offer1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.landing-offer .content-wrapper ul li:last-child div {
  background: url("../../Assets/Images/offer2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.landing-offer .content-wrapper ul li {
  max-width: 442px;
  box-shadow: 0px 4px 20px rgba(227, 232, 235, 0.25);
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
}

.landing-offer .content-wrapper ul li h3 {
  font-size: 24px;
  line-height: 36px;
  color: #272727;
  margin-bottom: 1rem;
  font-weight: 600;
}

.landing-offer .content-wrapper ul li p {
  font-size: 16px;
  line-height: 24px;
  color: #585858;
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  .landing-offer .content-wrapper ul {
    grid-template-columns: 1fr 1fr;
  }
}

.landing-faqs {
  background: linear-gradient(
    180.07deg,
    rgba(243, 250, 255, 0.72) 0.06%,
    rgba(219, 235, 249, 0) 99.94%,
    rgba(239, 233, 253, 0.07) 99.94%
  );
}

.landing-faqs .content-wrapper h2 {
  font-size: 32px;
  line-height: 32px;
  color: #000;
  margin-bottom: 4rem;
  font-weight: 500;
}

.landing-faqs .content-wrapper h2 span {
  color: #0084d1;
}

.faqs-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.more-faqs-btn {
  margin-top: 20px;
  padding: 11px 16px;
  background-color: #fff;
  border: 1px solid #0084d1;
  color: #0084d1;
}

.faqs-enquire {
  max-width: 900px;
  margin: 0rem auto;
  margin-top: 4rem;
  padding: 48px;
  background-color: #0084d1;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.faqs-enquire .content p:first-child {
  color: #fff;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.faqs-enquire .content p:last-child {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.faqs-enquire .input {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faqs-enquire .input button {
  border-radius: 8px;
  padding: 11px 16px;
}

.faqs-enquire .input input {
  flex: 1;
}

@media only screen and (min-width: 768px) {
  .faqs-enquire {
    flex-direction: row;
  }

  .faqs-enquire .content {
    flex: 1;
  }

  .faqs-enquire .input {
    flex-direction: row;
  }
}
