.static {
  background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.96%);
}

.static .content-wrapper {
  position: relative;
  z-index: 4;
}

.static .wrapper {
  overflow: hidden;
  padding-bottom: 4rem;
}

/* @media only screen and (min-width:768px) {
    .container .wrapper.landing-hero {
        padding-bottom: 6rem;
    }
} */

.static .wrapper::before {
  /* min-width: 1400px; */
  position: absolute;
  content: "";
  top: 100px;
  overflow: hidden;
  width: 100%;
  left: 0rem;
  opacity: 0.3;
  height: 600px;
  width: 100%;
  z-index: 3;
  background: url("./Assets/Images/city-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

.static .wrapper::after {
  /* min-width: 1400px; */
  position: absolute;
  width: 100%;
  left: 0%;
  height: 600px;
  top: 205px;
  content: "";
  z-index: 2;
  background: url("./Assets/Images/city-bg.png");
  opacity: 0.1;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

.static .services-tabs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  position: relative;
  z-index: 4;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .static .services-tabs {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .static .services-tabs {
    grid-template-columns: 1fr 1fr;
  }
}

.static .services-tabs li {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.static .services-tabs li h3 {
  font-size: 20px;
  line-height: 20px;
  color: #666666;
  font-weight: 500;
}

.static .services-tabs li:first-child h3 {
  color: #ab4618;
}

.static .services-tabs li:first-child div {
  background: url("./Assets/Images/Realestate.svg");
  width: 64px;
  height: 64px;
}

.static .services-tabs li:nth-child(2) div {
  background: url("./Assets/Images/Financialservices.svg");
  width: 64px;
  height: 64px;
}

/* .static .services-tabs li:last-child div {
  background: url("./Assets/Images/Medicalservices.svg");
  width: 64px;
  height: 64px;
} */

.static .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.static p,
.static li {
  font-size: 12px;
  color: #000;
  gap: 4px;
}

.static a {
  font-weight: 600;
  text-decoration: underline;
  color: #0084d1;
}

.static h2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #272727;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 2rem;
}

.static h3 {
  font-size: 16px;
  font-weight: 500;
  color: #eb585c;
}

.static h4 {
  color: #eb585c;
  font-size: 14px;
  font-weight: 500;
}

.static h5 {
  color: #eb585c;
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 500;
}

.static ol,
.static ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.static ol li {
  list-style: decimal;
}

.static ul li {
  list-style: square;
}

.static ol li ol li {
  list-style: inside;
}

/* Contact Us Page */

.contact-us {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-us section {
  width: 100%;
  height: max-content;
}

.contact-us section:first-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .contact-us {
    flex-direction: row;
  }

  .contact-us section:first-child {
    align-items: flex-start;
    width: 980px;
  }
}

.contact-us .address {
  height: max-content;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .contact-us .address {
    align-items: flex-start;
    margin-top: 5rem;
  }
}

.contact-us .address h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: #0f1c49;
}

.contact-us section:nth-child(2) {
  height: max-content;
  padding: 2rem 2rem;
  background: #f8fbfc;
  border-radius: 16px;
  gap: 2rem;
}

.contact-us section:nth-child(2) h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: left;
  color: #0f1c49;
}

.contact-us section:nth-child(2) p,
.contact-us .address p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #878da4;
}

.contact-us .message {
  padding-top: 2rem;
}

.contact-us .message input,
.contact-us .message textarea {
  border: 1px solid #eeeeee;
  margin-bottom: 1rem;
}

.contact-us .message button {
  margin-top: 1rem;
  width: 100%;
}
