.main_container {
 margin: 1.5rem auto;
 width: 100%;
}
.card_container {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
 height: 210px;
 width: 100%;
}
.card_container_sold {
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
 height: 181px;
 width: 100%;
 opacity: 0.2;
}
.card_flex_img {
 width: 35%;
 height: 100%;
 position: relative;
}
.prpty_img {
 border-radius: 1rem 0rem 0rem 1rem;
}
.prpty_add_img {
 cursor: pointer;
 border-radius: 1rem 0rem 0rem 1rem;
}
.card_flex_detail {
 width: 65%;
 height: 100%;
 /* width: 457px; */
 /* height: 171px; */
 flex-shrink: 0;
 border-radius: 0px 8px 8px 0px;
 background: #f8f8f8;
 padding: 1rem;
}
.status_tag {
 color: #747474;
 background-color: #eaeaea;
 border: 1px solid #999;
 position: absolute;
 height: 30px;
 padding: 0rem 0.5rem;
 border-right: none;
 /* width: 80px; */
 display: flex;
 justify-content: center;
 align-items: center;
 top: 20px;
 right: 0px;
 border-radius: 8px 0px 0px 8px;
}
.load_more_btn {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
 text-align: center;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.load_more_btn > label {
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
 color: #0084d1;
 display: flex;
 justify-content: center;
 column-gap: 0.5rem;
 align-items: center;
 cursor: pointer;
 /* border: 1px solid; */
}
.prprty_response {
 display: flex;
 justify-content: space-between;
 align-items: center;
 align-self: flex-end;
 margin-top: 5%;
}

.prp_name {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
}
.location {
 color: #747474;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 display: flex;
 align-items: center;
 column-gap: 0.7rem;
 margin: 0.4rem 0rem;
 flex-wrap: wrap;
 /* text-decoration-line: underline; */
}
.about_prp {
 color: #000;
 font-family: Poppins;
 font-size: 16px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
}
.location_helper {
 color: #878da4;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 text-decoration-line: none;
}
.prprty_response > span {
 display: flex;
 justify-content: flex-start;
 column-gap: 0.7rem;
 align-items: center;
}
.prprty_response > span > li {
 color: #999;
 font-family: Poppins;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
}
.prprty_response button {
 padding: 11px 14px;
}
.dot {
 background-color: #c4c4c4;
 height: 4px;
 width: 4px;
 border-radius: 50%;
}

.image_notify {
 background-color: #e07f51;
 width: 85%;
 height: 3rem;
 margin: auto;
 display: flex;
 justify-content: space-between;
 align-items: center;
 border-radius: 0px 0px 8px 8px;
 padding: 0rem 1rem;
}
.image_notify_txt {
 color: #ffffff;
}
.more_option_div {
 display: flex;
 justify-content: space-between;
 align-items: center;
 position: relative;
}
.more_option_div > div {
 position: relative;
}
.triangle_up {
 /* width: 0;
	height: 0;
   position: absolute;
   top: 60%;
   right: -25%;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 20px solid #fff;
   text-shadow: 0px 0px 8px 8px #e9f7ff; */

 position: absolute;
 left: 0px;
 right: 0px;
 bottom: 0;
 /* top: 100px;  */
 width: 100%;
 height: auto;
 /* z-index: 999;  */
 background-color: red;
 /* opacity: 0.5; */
}
.edit_del_btns {
 position: absolute;
 border-radius: 4px;
 border: 1px solid #e9f7ff;
 display: flex;
 flex-direction: column;
 row-gap: 0.5rem;
 left: -2.5rem;
 top: 120%;
 /* background: #FFF;
 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10); */
 background: #fff;
 box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
 padding: 0.5rem;
 text-align: center;
}
.edit_del_btns > p {
 /* background-color: #e9f7ff; */
 color: #000;
 padding: 0.2rem;
}
.edit_del_btns > p:hover {
 background-color: #f4f4f4;
 padding: 0.2rem;
 border-radius: 0.5rem;
 color: #000;
 cursor: pointer;
}
@media (max-width: 600px) {
 .card_container {
  flex-direction: column;
  height: fit-content;
 }
 .card_flex_img {
  width: 100%;
  height: 250px;
  position: relative;
 }
 .card_flex_detail {
  width: 100%;
 }
 .image_notify {
  flex-direction: column;
  row-gap: 0.5rem;
  padding-bottom: 0.5rem;
  padding: 0.5rem;
  height:fit-content;
 }
 .card_container_sold {
  flex-direction: column;
  height: 100%;
 }
 .prpty_img {
  border-radius: 1rem;
 }
 .edit_del_btns {
  position: absolute;
  top: 1.3rem;
 }
}
