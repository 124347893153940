.admin-container .dashboard .statistics {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem 1rem;
    padding-top: 32px;
}

.admin-container .dashboard .statistics li {
    background-color: #fff;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 0 solid rgba(0, 0, 0, .125);
    position: relative;
}

.admin-container .dashboard .statistics li .header {
    position: absolute;
    background-color: #63a4ff;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%), 0 7px 10px -5px rgb(0 0 0 / 6%);
    left: 1rem;
    top: -32px;
}

.admin-container .dashboard .statistics li .header svg {
    color: #fff;
}

.admin-container .dashboard .statistics li .content {
    padding-left: 64px;
    text-align: right;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
}

.admin-container .dashboard .statistics li .content span:first-child {
    font-weight: 300;
    font-size: 0.875rem;
    color: #7b809a;
}

.admin-container .dashboard .statistics li .content span:last-child {
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    color: #344767;
}

.admin-container .dashboard .statistics li .footer {
    background-color: transparent;
    padding: 0rem;
    padding-top: 8px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
}

.admin-container .dashboard .statistics li .footer p {
    font-size: 12px;
    color: #7b809a;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 4px;
}

@media only screen and (min-width: 551px) {
    .admin-container .dashboard .statistics {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 768px) {
    .admin-container .dashboard .statistics {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1024px) {
    .admin-container .dashboard .statistics {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}