.appointment-modal {
  position: fixed;
  z-index: 1000;
  top: 0rem;
  left: 0rem;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.appointment-modal .appointment-wrapper {
  position: relative;
  padding: 4rem 2rem 3rem 2rem;
  background-color: #eff4fe;
  border-radius: 8px;
  max-width: 500px;
  max-height: calc(100vh - 2rem);
  overflow-y: auto;
}

.appointment-modal .appointment-wrapper .close-btn {
  padding: 0rem;
  background-color: transparent;
  border-radius: 0px;
  position: absolute;
  top: 24px;
  right: 32px;
}

.appointment-modal .appointment-wrapper .form {
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  height: max-content;
  gap: 1rem;
  border-bottom: 1px solid #9496a0;
}

.appointment-modal .appointment-wrapper .form h2 {
  font-size: 36px;
  font-weight: 500;
  color: #272727;
}

.appointment-modal .appointment-wrapper .form p {
  font-size: 16px;
  font-weight: 500;
  color: #272727;
  margin-bottom: 8px;
}

.appointment-modal .appointment-wrapper .form select {
  width: 100%;
  background-color: #fff;
  padding: 20px 24px 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  border-radius: 32px;
  transition: 0.1s all linear;
}

.appointment-modal .appointment-wrapper .schedules {
  padding-top: 2rem;
  position: relative;
}
.appointment-modal .appointment-wrapper .schedules > p:last-child {
  position: absolute;
  color: #0084d1;
  font-size: 12px;
}

.appointment-modal .appointment-wrapper .schedules .availability {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  align-items: center;
}

.appointment-modal .appointment-wrapper .schedules .availability p,
.appointment-modal .appointment-wrapper .book-appointment p,
.appointment-modal .appointment-wrapper .schedules h3 {
  font-size: 16px;
  font-weight: 500;
  color: #272727;
}

.appointment-modal .appointment-wrapper .schedules .availability span,
.appointment-modal .appointment-wrapper .book-appointment span {
  font-size: 16px;
  font-weight: 500;
  color: #0084d1;
}

.appointment-modal .appointment-wrapper .schedules .scheduler {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.appointment-modal .appointment-wrapper .schedules .scheduler h4 {
  font-size: 14px;
  font-weight: 500;
  color: #4c535b;
  text-align: center;
}

.appointment-modal .appointment-wrapper .schedules .days {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1rem;
  border-bottom: 1px solid #b2c1dd;
}

.appointment-modal .appointment-wrapper .schedules button {
  padding: 0rem;
  background-color: transparent;
  border-radius: 0px;
}

.appointment-modal .appointment-wrapper .schedules .days button svg {
  color: #0084d1;
}

.appointment-modal .appointment-wrapper .schedules .days .day {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

.appointment-modal .appointment-wrapper .schedules .days .day span {
  font-size: 12px;
  font-weight: 400;
  color: #707781;
}

.appointment-modal .appointment-wrapper .schedules .days .day button {
  color: #4c535b;
  font-size: 12px;
  font-weight: 400;
}

.appointment-modal .appointment-wrapper .schedules .days .day button.active {
  border-radius: 50%;
  border: 1px solid #0084d1;
  color: #0084d1;
  width: 24px;
  height: 24px;
}

.appointment-modal .appointment-wrapper .schedules .slots {
  padding-top: 1rem;
}

.appointment-modal .appointment-wrapper .schedules .slots .header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.appointment-modal .appointment-wrapper .schedules .slots .header h3 {
  flex: 1;
}

.appointment-modal .appointment-wrapper .schedules .slots .header button {
  color: #4c535b;
  font-size: 14px;
  font-weight: 500;
}

.appointment-modal
  .appointment-wrapper
  .schedules
  .slots
  .header
  button.active {
  color: #0084d1;
}

.appointment-modal .appointment-wrapper .schedules .slots ul li button {
  width: 82px;
  height: 49px;
  border-radius: 25px;
  background-color: #fff;
  color: #626573;
  font-size: 14px;
  font-weight: 500;
}

.appointment-modal
  .appointment-wrapper
  .schedules
  .slots
  ul
  li
  button.disabled {
  background-color: #e1e4e5;
  color: #979797;
}

.appointment-modal .appointment-wrapper .schedules .slots ul li button.active {
  border: 1px solid #0084d1;
  color: #0084d1;
}

.appointment-modal .appointment-wrapper .schedules .slots ul {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 175px;
  overflow-y: auto;
}
.appointment-modal .appointment-wrapper .schedules .action-button {
  display: flex;
}
.appointment-modal .appointment-wrapper .schedules .action {
  background-color: #0084d1;
  padding: 11px 16px;
  display: block;
  border-radius: 48px;
  margin: 2rem auto 0rem auto;
}

@media only screen and (min-width: 1024px) {
  .appointment-modal .appointment-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1100px;
  }

  .appointment-modal .appointment-wrapper .form {
    padding-bottom: 0rem;
    padding-right: 2rem;
    border-bottom: 0px;
    border-right: 1px solid #9496a0;
  }

  .appointment-modal .appointment-wrapper .schedules {
    padding-top: 0rem;
    padding-left: 2rem;
    position: relative;
  }
  .appointment-modal .appointment-wrapper .schedules > p {
    bottom: 0;
  }
  .appointment-modal .appointment-wrapper .book-appointment {
    padding: 100px;
    text-align: center;
    left: 50%;
    right: 50%;
    position: relative;
  }
}

@media only screen and (min-width: 768px) {
  .appointment-modal .appointment-wrapper .book-appointment {
    padding: 100px;
  }
}

.appointment-modal .appointment-wrapper .book-appointment {
  text-align: center;
}

.appointment-modal .appointment-wrapper .book-appointment p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
