.modal-container .modal-popup .modal-content {
  padding: 2rem 1rem;
}
.modal-content .top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content .top .image .like {
  background: url("../../../Assets/Images/Like.svg");
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
.modal-content .top .image {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}
.modal-content .top .heading h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2eb300;
}
.modal-content .top .heading p {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #828597;
}
.modal-content > p {
  color: #828597;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
