.input_div > input::placeholder {
  color: #bfbfbf;
}
.container {
  width: 70%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 20px;
}
.navigator_link {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  width: 80%;
  margin: auto;
  margin-top: 7rem;
}
.main_heading {
  color: var(--title, #0f1c49);
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.subheading {
  color: var(--paragraph, #585858);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
}
.thumbnail_div {
  border: 2px dashed black;
  height: 190px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  position: relative;
  cursor: pointer;
}
.thumbnail_div:hover {
  border: 2px dashed #ff5c83;
}
.thumbnail_div > p {
  display: none;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
}
.thumbnail_div:hover > p {
  display: block;
  color: #ff5c83;
}
.input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #cdcdcd;
  position: relative;
}
.carpet_input_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.carpet_first_child {
  width: 30%;
  border-bottom: 1px solid #cdcdcd;
}
.carpet_second_child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  column-gap: 2rem;
}
.carpet_input_div > div > label {
  color: var(--textfield-text, #828597);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  width: 40%;
  height: 50px;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
}
.input_div > p {
  display: none;
}
/* .input_div:hover > p {
 display: block;
 position: absolute;
 right: 0.5rem;
 bottom: 0.5rem;
 color: red;
} */
.input_div:hover {
  border-radius: 4px;
  border: 1px solid #ff5c83;
}
.input_div > label {
  color: var(--textfield-text, #828597);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  width: 40%;
  padding: 0rem 1rem;
}
.input_feild {
  border-radius: 0px;
  color: var(--textfield-text, #828597);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  height: 50px;
}
.input_feild + .inut_div {
  border: 1px solid red;
  border-radius: 0px;
}
.container > section {
  margin-top: 3rem;
}
.section_heading {
  color: var(--title, #0f1c49);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 1rem;
}
.delete_img {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.add_img_btn {
  height: 50px;
  width: 130px;
  flex-shrink: 0;
  background-color: #fff;
  border: 1px solid #8b8b8b;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  column-gap: 0.2rem;
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;
}
.images_div {
  border: 2px dashed black;
  height: min-content;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.images_div > div {
  position: relative;
  height: 140px;
  width: 140px;
}

.select_property_type_div {
  display: flex;
  width: 100%;
  border-radius: none;
  align-items: center;
  column-gap: 0.3rem;
  justify-content: space-between;
  padding-right: 10px;
}
.carpet_input_div > div > input {
  border-bottom: 1px solid #cdcdcd;
  border-radius: none;
}
.carpet_select_property_type_div {
  border-bottom: 1px solid #cdcdcd;
  display: flex;
  width: 100%;
  border-radius: none;
  align-items: center;
  column-gap: 0.3rem;
  justify-content: space-between;
  padding-right: 10px;
}
.select_property_type {
  display: flex;
  border-radius: 8px;
  /* background: #D5DBFF; */
  width: 100%;
  padding-left: 24px;
  height: 50px;
  padding-right: 10px;
  text-align: left;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  color: var(--textfield-text, #828597);
  -webkit-appearance: none;
  -moz-appearance: none;
}
.property_detail_div {
  border-radius: 16px;
  border: 2px dashed #32b3ff;
  background: #f5fbff;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
  padding: 1rem 2rem;
}
.prp_detail_flex {
  border-radius: 16px;
  border: 2px dashed #32b3ff;
  background: #f5fbff;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 2rem;
  padding: 1rem 2rem;
}
.titles {
  color: var(--textfield-text, #828597);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.3rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
}
.doc_selected {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
}

.prp_detail_heading {
  color: var(--title, #0f1c49);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.prp_detail_sub_head {
  color: #222;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.save_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: 14rem;
  height: 42px;
}
.download_btn_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.download_btn_div .download_btn {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--paragraph, #585858);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 600px) {
  .container {
    width: 90%;
  }
  .images_div {
    column-gap: 1rem;
    padding: 20px 10px;
  }
  .images_div > div {
    height: 130px;
    width: 130px;
  }
  .doc_selected label:first-child img {
    width: 22px;
  }
  .prp_detail_heading {
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
  }
  .doc_selected {
    column-gap: 0.5rem;
  }
  .property_detail_div {
    padding: 1rem 0.5rem;
  }
}
