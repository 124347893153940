.container {
 height: 100%;
 padding-top: 80px;
 width: 70%;
 margin: auto;
 margin: 20px auto;
 /* margin-top: 100px; */
}
.navigator_link {
 color: #000;
 font-family: Poppins;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 100%;
}
.location {
 color: #000;
 font-family: Poppins;
 font-size: 16px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
 text-decoration-line: underline;
}
.prp_detail_section {
 display: flex;
 align-items: flex-start;
 justify-content: space-between;
 column-gap: 10%;
}
.bid_amount {
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.detail_div {
 width: 50%;
}
.prp_img_div {
 height: 40vh;
 width: 100%;
 position: relative;
}
.prop_img_section {
 display: flex;
 align-items: center;
 justify-content: space-between;
 position: absolute;
 bottom: 0.5rem;
 width: 100%;
 color: white;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 padding: 0rem 0.5rem;
}
.dt_vi_li {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 column-gap: 0.2rem;
}
.prp_img_div > img {
 border-radius: 32px 32px 0px 0px;
 height: 100%;
 width: 100%;
}
.more_img_btn {
 border-radius: 10px;
 opacity: 0.8;
 background: #fff;
 width: 111px;
 height: 75.605px;
 display: flex;
 align-items: center;
 justify-content: center;
}
.more_img {
 border-radius: 8px;
 background: rgba(135, 141, 164, 0.8);
 /* height: fit-content; */
 display: flex;
 justify-content: center;
 align-items: center;
 column-gap: 0.2rem;
 cursor: pointer;
 padding: 0.1rem 0.7rem;
}
.img_price_detail {
 border-radius: 0px 0px 32px 32px;
 background: #fff;
 box-shadow: 0px 0px 8px 0px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 1rem;
}
.label {
 color: #8a8a8a;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
}
.bold_price {
 color: #000;
 font-family: Poppins;
 font-size: 18px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
}
.des_text {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 margin: 2rem 0rem 1rem 0rem;
}
.accept_btn {
 display: flex;
 justify-content: center;
 align-items: center;
 column-gap: 1rem;
}
.des_content {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
}
.bid_detail_div {
 width: 35%;
}
.high_bid_card {
 border: 1px solid #eee;
 border-radius: 16px;
 padding: 1rem;
 background: #fff;
}
.high_bid_card > div {
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.bidder_heading {
 color: #000;
 font-family: Poppins;
 font-size: 20px;
 font-style: normal;
 font-weight: 500;
 line-height: 100%;
 margin: 1.5rem 0rem;
}
.high_bid_txt {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: normal;
}
.all_bid_btn {
 display: flex;
 flex-direction: row-reverse;
 margin: 1.5rem 0rem 1rem 0rem;
 align-items: center;
}
.all_view_bid_btn {
 color: #0084D1;
 text-align: right;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
 background-color: white;
}
.subcard_div {
 border: 1px solid #eee;
 border-radius: 16px;
 padding: 1rem 1rem 0.5rem 1rem;
 background: #fff;
 margin-bottom: 1rem;
}
.bidder_name {
 color: #222;
 /* leading-trim: both;
 text-edge: cap; */
 font-family: Poppins;
 font-size: 18px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
}
.hr_line {
 width: 100%;
 border: 1px solid #eee;
}
.action_btn_div {
 display: flex;
 justify-content: space-around;
 align-items: center;
 height: 100%;
}
.action_btn_div > .accept_btn {
 width: 50%;
 text-align: center;
 color: #0084D1;
 text-align: center;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 24px;
}
.action_btn_div_inactive {
 color: #FF5C83;
 text-align: center;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 24px;
}
.ver_line {
 height: 2rem;
 border: 1px solid #eee;
}
.view_more_btn {
 color: #0084D1;
 text-align: center;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
 cursor: pointer;
 margin: 1rem 0rem;
}
.acc_heading {
 color: #5e5e5e;
 font-family: Poppins;
 font-size: 32px;
 font-style: normal;
 font-weight: 500;
 line-height: 32px;
}
.accordian {
 margin-bottom: 1rem;
 cursor: pointer;
}
.prp_view {
 display: flex;
 justify-content: center;
 align-items: center;
 column-gap: 0.5rem;
 border-radius: 24px;
 border: 1px solid #eee;
 opacity: 0.6;
 background: #272727;
 padding: 8px 16px;
 width: 100%;
 height: 2rem;
}
.sold_toggle_div {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 0.5rem;
 margin-top: 1rem;
 width: 100%;
 border-radius: 48px;
 border: 1px solid #e9f7ff;
 background: #fff;
 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
}
.sold_toggle_div > p {
 color: var(--textfield-text, #828597);
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
}

.switch {
 position: relative;
 display: inline-block;
 width: 50px;
 height: 24px;
}
.switch input {
 opacity: 0;
 width: 0;
 height: 0;
}

.slider {
 position: absolute;
 cursor: pointer;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: #ccc;
 -webkit-transition: 0.4s;
 transition: 0.4s;
}

.slider:before {
 position: absolute;
 content: "";
 height: 18px;
 width: 18px;
 left: 4px;
 bottom: 4px;
 background-color: white;
 -webkit-transition: 0.4s;
 transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  

input:checked + .slider:before {
 -webkit-transform: translateX(26px);
 -ms-transform: translateX(26px);
 transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
 border-radius: 34px;
}

.slider.round:before {
 border-radius: 50%;
}
@media (max-width: 768px) {
 .prp_detail_section {
  column-gap: 3%;
 }
 .img_price_detail {
  flex-wrap: wrap;
  row-gap: 0.5rem;
 }
 .detail_div {
  width: 60%;
  margin: auto;
 }
 .bid_detail_div {
  width: 40%;
 }
}
@media (max-width: 600px) {
 .container {
  width: 90%;
  height: 100%;
 }
 .prp_detail_section {
  flex-direction: column;
 }
 .detail_div {
  width: 100%;
  margin: auto;
 }
 .img_price_detail {
  flex-wrap: wrap;
  row-gap: 0.5rem;
 }
 .bid_detail_div {
  width: 100%;
 }
}
/* 175618073 */
