.details .price-inputs {
  display: flex;
  align-items: center;
  width: 100%;
}

.details .price-inputs input[type="number"] {
  border: none;
  outline: none;
  width: 100%;
  border: 1px solid #eeeeee;
  border-left: 0;
  border-radius: 0px 24px 24px 0px;
  padding: 14px;
  font-size: 16px;
}
.details .price-inputs .currency-select {
  width: 40%;
}
.details .price-inputs .price {
  width: 89%;
}
.details .price-inputs select {
  border: none;
  outline: none;
  border: 1px solid #eeeeee;
  border-right: 0;
  border-radius: 24px 0px 0px 24px;
  padding: 14px;
  font-size: 16px;
  width: 100%;
  /* border-right: 1px solid #ccc; */
}
.details .consultation {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.details .consultation input[type="checkbox"] {
  margin: 3%;
  width: 15px;
  height: 15px;
}
.details .consultation label {
  font-size: 16px;
  font-weight: 400;
  color: #0084d1;
}
@media only screen and (min-width: 1024px) {
  .details .price-inputs .currency-select {
    width: 15%;
  }
  .details .consultation {
    margin-top: 0;
  }
}
