.container .wrapper.financial-hero {
 background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
 padding-bottom: 4rem;
 overflow: hidden;
 height: 1000px;
}

.financial-hero-background::before {
 /* min-width: 1400px; */
 position: absolute;
 content: "";
 top: 250px;
 opacity: 0.7;
 overflow: hidden;
 width: 100%;
 left: 0rem;
 height: 600px;
 width: 100%;
 z-index: 3;
 background: url("../../../Assets/Images/city-bg.png");
 background-repeat: no-repeat;
 background-position: center;
 background-size: 100% 600px;
}

.financial-hero-background::after {
 /* min-width: 1400px; */
 position: absolute;
 width: 100%;
 left: 0%;
 height: 600px;
 top: 350px;
 content: "";
 z-index: 2;
 background: url("../../../Assets/Images/city-bg.png");
 opacity: 0.1;
 transform: matrix(1, 0, 0, -1, 0, 0);
 background-repeat: no-repeat;
 background-position: center;
 background-size: 100% 600px;
}

.financial-data-container {
 margin-top: -350px;
 position: relative;
 z-index: 5;
}

@media only screen and (min-width: 768px) {
 .financial-hero::after {
  top: 300px;
 }

 .financial-hero::before {
  top: 200px;
 }

 .container .wrapper.financial-hero {
  height: 800px;
 }

 .financial-data-container {
  margin-top: -400px;
 }
}

.financial-hero .services-tabs {
 display: grid;
 grid-template-columns: 1fr;
 gap: 16px;
 position: relative;
 z-index: 4;
}

@media only screen and (min-width: 768px) {
 .financial-hero .services-tabs {
  grid-template-columns: 1fr 1fr;
 }
}

@media only screen and (min-width: 1024px) {
 .financial-hero .services-tabs {
  grid-template-columns: 1fr 1fr;
 }
}

.financial-hero .services-tabs li {
 width: 100%;
 background-color: #fff;
 padding: 1rem;
 border-radius: 1rem;
 padding: 1rem;
 box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);
 display: flex;
 flex-wrap: wrap;
 gap: 1rem;
 align-items: center;
 cursor: pointer;
}

.financial-hero .services-tabs li h3 {
 font-size: 20px;
 line-height: 20px;
 color: #666666;
 font-weight: 500;
}

.financial-hero .services-tabs li:nth-child(2) h3 {
 color: #6716a1;
}

.financial-hero .services-tabs li:first-child div {
 background: url("../../../Assets/Images/realestateGrey.svg");
 width: 64px;
 height: 64px;
}

.financial-hero .services-tabs li:last-child div {
 background: url("../../../Assets/Images/Financialservices.svg");
 width: 64px;
 height: 64px;
}

/* .financial-hero .services-tabs li:last-child div {
  background: url("../../../Assets/Images/medicalGrey.svg");
  width: 64px;
  height: 64px;
} */

.financial-search .sort {
 display: flex;
 gap: 8px;
 margin-left: auto;
 margin-top: 2rem;
 align-items: center;
 margin-bottom: 2rem;
}

.financial-search .sort svg {
 color: #626573;
}

.financial-search .sort span,
.financial-search .sort select {
 font-size: 14px;
 color: #090041;
 font-weight: 400;
 background-color: transparent;
}

.financial-search .sort span {
 font-weight: 500;
}

.financial-search {
 display: flex;
 flex-direction: column;
 gap: 1rem;
 align-items: center;
 margin-top: 3rem;
 z-index: 4;
 position: relative;
}

.financial-search h2 {
 font-weight: 500;
 font-size: 24px;
 line-height: 36px;
 color: #272727;
}

.financial-search .search-bar {
 display: flex;
 flex-direction: column;
 gap: 8px;
 align-items: center;
 width: 100%;
 max-width: 700px;
}

.financial-search .search-bar .search-bar-inputs {
 display: flex;
 align-items: center;
 box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.05);
 border-radius: 100px;
}

.financial-search .search-bar select {
 background-color: #fff;
 height: 48px;
 border-radius: 100px 0px 0px 100px;
 padding: 0rem 32px 0px 32px;
 font-size: 14px;
 font-weight: 500;
 color: #272727;
 -moz-appearance: none;
 -webkit-appearance: none;
 min-width: 80px;
}

.financial-hero .search-bar .plan-input {
 width: 100%;
 display: flex;
 align-items: center;
 /* gap: 2%; */
 position: relative;
}

.financial-search .search-bar button {
 white-space: nowrap;
}

.financial-hero .search-bar .plan-input input {
 padding: 1rem;
}
.financial-hero .search-bar .plan-input p {
 cursor: pointer;
 font-weight: 800;
 font-size: large;
 /* margin-right: 50px; */
 position: absolute;
 right: 20px;
}
.financial-hero .search-bar .search-select {
 position: relative;
}

.financial-hero .search-bar .search-select .down-icon {
 position: absolute;
 right: 12px;
 top: 50%;
 color: #272727;
 transform: translateY(-50%);
}

.financial-hero .search-bar .search-select .location-icon {
 position: absolute;
 left: 10px;
 top: 50%;
 color: #272727;
 transform: translateY(-50%);
}

.financial-plans .plans {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
 }
 
 .plans-actions-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
  position: absolute;
  top: 40%;
  right: 0rem;
 }
 .plans-actions-left {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 1rem;
   margin-top: 4rem;
   position: absolute;
   top: 40%;
   left: 0rem;
 }
 
 .plans-actions-left button {
  padding: 0rem;
  width: 60px;
  height: 42px;
  border-radius: 2rem;
  background-color: #fff;
 }
 
 .plans-actions-left button svg {
  color: #979797;
 }
 
 .plans-actions-left .active {
  box-shadow: 0px 4px 20px 0px #adadad4d;
 }
 
 .plans-actions-left .active svg {
  color: #0084d1;
 }
 
 .plans-actions-right button {
   padding: 0rem;
   width: 60px;
   height: 42px;
   border-radius: 2rem;
   background-color: #fff;
  }
  
  .plans-actions-right button svg {
   color: #979797;
  }
  
  .plans-actions-right .active {
   box-shadow: 0px 4px 20px 0px #adadad4d;
  }
  
  .plans-actions-right .active svg {
   color: #0084d1;
  }
@media only screen and (min-width: 768px) {
 .financial-search .search-bar {
  background-color: #fff;
  padding: 1px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.05);
  flex-direction: row;
  gap: 0px;
  border-radius: 100px;
  overflow: hidden;
 }

 .financial-search .search-bar {
  box-shadow: none;
  border-radius: 0px;
  align-items: center;
  border-radius: 100px;
  background-color: #fff;
 }

 .financial-plans .content-wrapper section {
  display: flex;
  gap: 1rem;
  align-items: center;
 }
 .plans-actions-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
  position: inherit;
 }
 .plans-actions-left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 4rem;
  position: inherit;
 }
}


@media only screen and (min-width: 768px) {
 .financial-plans .plans {
  grid-template-columns: 1fr 1fr;
 }
}

@media only screen and (min-width: 1024px) {
 .financial-plans .plans {
  grid-template-columns: 1fr 1fr 1fr;
 }
}

.financial-plans .content-wrapper p {
 margin-bottom: 2rem;
}

.financial-plans .plans .plan {
 background-color: #fff;
 box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
 border-radius: 8px;
 padding: 2rem;
 display: flex;
 flex-direction: column;
 height: max-content;
}

.financial-plans .plans .plan .price {
 font-size: 12px;
 color: #979797;
}

.financial-plans .plans .plan .price b {
 color: #0084d1;
 font-weight: 500;
 font-size: 2rem;
 margin-right: 8px;
}

.financial-plans .plans .plan h3 {
 text-align: center;
 font-size: 24px;
 margin-bottom: 1.5rem;
 color: #272727;
 font-weight: 500;
 text-transform: capitalize;
}

.financial-plans .plans .plan .desc {
 font-size: 14px;
 margin-bottom: 8px;
 font-weight: 600;
 color: #585858;
}

.financial-plans .plans .plan .addons {
 display: flex;
 gap: 4px;
 font-size: 12px;
 color: #585858;
 align-items: center;
}

.financial-plans .plans .plan .addons input {
 width: 12px;
 height: 12px;
}

.financial-plans .plans .plan ul {
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
 gap: 4px;
}

.financial-plans .plans .plan ul li {
 position: relative;
 padding-left: 24px;
 font-size: 14px;
 font-weight: 400;
 color: #585858;
}

.financial-plans .plans .plan ul li svg {
 position: absolute;
 left: 0px;
 top: 5px;
 background: rgba(124, 213, 24, 0.2);
 color: #88d92f;
 /* border: 2px solid #7CD518; */
 width: 12px;
 background-color: #e3f3ce;
 height: 12px;
 border-radius: 50%;
}

.financial-plans .plans .plan button {
 margin: 0rem auto;
 display: block;
}
