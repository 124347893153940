.container .wrapper.realestate-hero {
  background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
  padding-bottom: 4rem;
  overflow: hidden;
  height: 1000px;
}

.container .wrapper.realestate-hero.short {
  height: 700px;
}

.realestate-hero-background::before {
  /* min-width: 1400px; */
  position: absolute;
  content: "";
  top: 250px;
  opacity: 0.7;
  overflow: hidden;
  width: 100%;
  left: 0rem;
  height: 600px;
  width: 100%;
  z-index: 3;
  background: url("../../../Assets/Images/city-bg.png");
  opacity: 0.2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

.realestate-hero-background::after {
  /* min-width: 1400px; */
  position: absolute;
  width: 100%;
  left: 0%;
  height: 600px;
  top: 400px;
  content: "";
  z-index: 2;
  background: url("../../../Assets/Images/city-bg.png");
  opacity: 0.1;
  transform: matrix(1, 0, 0, -1, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 600px;
}

.container .realestate-hero .back-btn {
  padding: 0rem;
  background-color: transparent;
  border-radius: 0px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #979797;
  font-size: 14px;
  font-weight: 500;
}

.realestate-data-container {
  margin-top: -485px;
  position: relative;
  z-index: 5;
}

@media only screen and (min-width: 768px) {
  .realestate-hero::after {
    top: 300px;
  }

  .realestate-hero::before {
    top: 200px;
  }

  .container .wrapper.realestate-hero {
    height: 800px;
  }

  .realestate-data-container {
    margin-top: -475px;
  }
}

.realestate-hero .services-tabs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  position: relative;
  z-index: 4;
}

@media only screen and (min-width: 1024px) {
  .realestate-hero .services-tabs {
    grid-template-columns: 1fr 1fr;
  }
}

.realestate-hero .services-tabs li {
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .realestate-hero .services-tabs {
    grid-template-columns: 1fr 1fr;
  }
  .realestate-hero .services-tabs li {
    width: auto;
  }
}
.realestate-hero .services-tabs li h3 {
  font-size: 20px;
  line-height: 20px;
  color: #666666;
  font-weight: 500;
}

.realestate-hero .services-tabs li:first-child h3 {
  color: #ab4618;
}

.realestate-hero .services-tabs li:first-child div {
  background: url("../../../Assets/Images/Realestate.svg");
  width: 64px;
  height: 64px;
}

.realestate-hero .services-tabs li:last-child div {
  background: url("../../../Assets/Images/financialGrey.svg");
  width: 64px;
  height: 64px;
}

/* .realestate-hero .services-tabs li:last-child div {
  background: url("../../../Assets/Images/medicalGrey.svg");
  width: 64px;
  height: 64px;
} */

.realestate-search {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-top: 3rem;
  z-index: 4;
  position: relative;
}

.realestate-search h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #272727;
}

.realestate-search .search-bar {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.realestate-search .search-bar .search-bar-inputs {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
}

.realestate-search .search-bar .search-bar-inputs select {
  background-color: #fff;
  height: 48px;
  border-radius: 100px 0px 0px 100px;
  padding: 0rem 32px 0px 32px;
  font-size: 14px;
  font-weight: 500;
  color: #272727;
  -moz-appearance: none;
  -webkit-appearance: none;
  min-width: 80px;
}

.realestate-hero .search-bar .search-bar-inputs .location-select select {
  border-radius: 100px;
  color: #9496a0;
}

.realestate-hero .search-bar .search-bar-inputs .search-select {
  position: relative;
}

.realestate-hero .search-bar .search-bar-inputs .search-select .down-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  color: #272727;
  transform: translateY(-50%);
}

.realestate-hero .search-bar .search-bar-inputs .search-select .location-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  color: #272727;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) {
  .realestate-search .search-bar {
    background-color: #fff;
    padding: 1px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04),
      0px 4px 16px rgba(0, 0, 0, 0.05);
    flex-direction: row;
    gap: 0px;
    border-radius: 100px;
    overflow: hidden;
  }

  .realestate-search .search-bar .search-bar-inputs {
    box-shadow: none;
    border-radius: 0px;
    align-items: center;
    background-color: #fff;
  }

  .realestate-search .search-bar .search-bar-inputs select {
    padding: 0rem 3rem;
  }

  .realestate-search .search-bar button {
    margin-left: 6rem;
  }
}

.realestate-top .header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}

.realestate-top .header h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #0f1c49;
}

.realestate-top .header a {
  color: #0084d1;
  font-size: 14px;
  font-weight: 500;
}

.realestate-top .content-wrapper ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.realestate-top .content-wrapper .content {
  display: flex;
  flex-direction: column;
}
.realestate-top .content-wrapper .content .sell-property {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.realestate-top .content-wrapper .content .sell-property p {
  text-align: center;
}
/* .realestate-top .content-wrapper .content .image-slider {
  background: url("../../../Assets/Images/Group\ 11922.jpg");
  width: 100%;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
} */
:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}
.swiper_container {
  height: 20rem;
  width: 37rem;
  /* padding: 1rem 0; */
  position: relative;
}

.swiper-slide {
  width: 17rem;
  /* height: 42rem; */
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 14rem;
    width: 20rem;
  }
  /* .swiper-slide {
    width: 28rem !important; */
  /* height: 36rem !important; */
  /* } */
  .swiper-slide img {
    width: 16rem !important;
    /* height: 36rem !important; */
  }
}

.swiper-slide img {
  width: 21rem;
  /* height: 42rem; */
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}
@media (max-width: 500px) {
  .slider-controler {
    display: none;
  }
}
@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}
/* .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 12px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 4px)
  );
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 30%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
} */

@media only screen and (min-width: 1024px) {
  .realestate-top .content-wrapper .content {
    flex-direction: row;
    justify-content: space-around;
  }
  .realestate-top .content-wrapper .content .image-slider {
    height: 400px;
  }
  .realestate-top .content-wrapper .content .sell-property {
    padding: 40px;
    margin: 20px;
    display: block;
  }
  .realestate-top .content-wrapper .content .sell-property p {
    text-align: left;
  }
}
.realestate-top .content-wrapper .content p {
  color: #272727;
  opacity: 1;
  font-size: 30px;
  line-height: 45px;
}
.realestate-top .content-wrapper .content button {
  margin-top: 20px;
}
@media only screen and (min-width: 551px) {
  .realestate-top .content-wrapper ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .realestate-top .content-wrapper ul {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .realestate-top .content-wrapper ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.realestate-top .content-wrapper ul li {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  border-radius: 1rem;
}

.realestate-top .content-wrapper ul li div {
  height: 150px;
}

.realestate-top .content-wrapper ul li div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem 1rem 0px 0px;
}

.realestate-top .content-wrapper ul li h4 {
  padding: 8px 1rem 0px 1rem;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #0f1c49;
}

.realestate-top .content-wrapper ul li p {
  color: #878da4;
  font-size: 12px;
  padding: 0px 1rem 8px 1rem;
}

.realestate-works {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0001) -9.97%,
    #ecf0f7 100%
  );
}
.realestate-works .content-wrapper {
  display: flex;
  gap: 4rem;
  justify-content: center;
}

.realestate-works section:first-child {
  grid-template-columns: 1fr;
  display: none;
}

.realestate-works section:first-child div:first-child {
  background: url("../../../Assets/Images/Illustration1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
}

.realestate-works section:first-child div:nth-child(2) {
  background: url("../../../Assets/Images/Illustration2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
}

.realestate-works section:first-child div:last-child {
  background: url("../../../Assets/Images/Illustration3.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 150px;
}
.realestate-works section:first-child div {
  width: 400px;
  height: 200px;
}

.realestate-works .content-wrapper section:last-child ul {
  display: grid;
  grid-template-columns: 1fr;
}

.realestate-works .content-wrapper section:last-child ul li {
  border-left: 1px dashed #7c898d;
  position: relative;
  padding: 0rem 2rem 0rem 3rem;
  max-width: 500px;
}
.realestate-works .content-wrapper section:last-child ul li:last-child {
  border: 0px;
}

.realestate-works .content-wrapper section:last-child ul li svg {
  position: absolute;
  left: -11px;
  background: rgba(124, 213, 24, 0.2);
  color: #7cd518;
  border: 2px solid #7cd518;
  top: -11px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.realestate-works .content-wrapper section:last-child ul li div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.realestate-works .content-wrapper section:last-child ul li div span {
  font-size: 40px;
  color: #626573;
  font-weight: 500;
  line-height: 40px;
}

.realestate-works .content-wrapper section:last-child ul li div h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #272727;
}

.realestate-works .content-wrapper section:last-child ul li div p {
  font-size: 16px;
  font-weight: 400;
  color: #272727;
}

.realestate-works .content-wrapper section:last-child ul li div p b {
  font-weight: 600;
}

.realestate-works .content-wrapper section:last-child ul li div progress {
  border-radius: 7px;
  width: 100%;
  height: 6px;
}

.realestate-works
  .content-wrapper
  section:last-child
  ul
  li
  div
  progress::-webkit-progress-bar {
  background-color: #f1f1f1;
  border-radius: 7px;
}

.realestate-works
  .content-wrapper
  section:last-child
  ul
  li
  div
  progress::-webkit-progress-value {
  background-color: #1aa2f8;
  border-radius: 7px;
}

/* .realestate-works .details {
  display: grid;
  gap: 6rem;
  grid-template-columns: 1fr;
}

.realestate-works .details .details-section {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}

.realestate-works .details .details-section:first-child .section-image {
  background: url("../../../Assets/Images/Illustration4.jpeg");
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
}

.realestate-works .details .details-section:last-child .section-image {
  background: url("../../../Assets/Images/Illustration5.jpeg");
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
}

.realestate-works .details .details-section .content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.realestate-works .details .details-section .content h2 {
  font-weight: 400;
  font-size: 32px;
  color: #0f1c49;
}

.realestate-works .details .details-section .content h2 span {
  color: #e07f51;
}

.realestate-works .details .details-section .content ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 2rem;
}

.realestate-works .details .details-section .content li p {
  color: #878da4;
  font-weight: 400;
  font-size: 18px;
}

.realestate-works .details .details-section .content li {
  position: relative;
}

.realestate-works .details .details-section .content li svg {
  position: absolute;
  left: -24px;
  top: 5px;
  background: rgba(124, 213, 24, 0.2);
  color: #88d92f;
  border: 2px solid #7CD518;
  width: 16px;
  background-color: #e3f3ce;
  height: 16px;
  border-radius: 50%;
}

.realestate-works .details .details-section .content button {
  padding: 11px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
} */

/* @media only screen and (min-width: 768px) {
  .realestate-works .details .details-section {
    grid-template-columns: 1fr 1fr;
  }  
} */
@media only screen and (min-width: 768px) {
  .realestate-works .content-wrapper section:first-child {
    display: grid;
  }
}
.realestate-results .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.realestate-results .filters-section h2 {
  font-weight: 400;
  font-size: 20px;
  color: #000;
  margin-bottom: 1.5rem;
}

.realestate-results .filters-section {
  width: 100%;
  max-width: 250px;
}

.realestate-results .filters-section .filters {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.realestate-results .filters-section .filters .filter {
  margin-bottom: 0rem;
}

.realestate-results .filters-section .filters .filter .header {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(233, 235, 240, 0.99);
}

.realestate-results .filters-section .filters .filter:last-child {
  border-top: 1px solid rgba(233, 235, 240, 0.99);
}

.realestate-results .filters-section .filters .filter .header h4 {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  flex: 1;
}

.realestate-results .filters-section .filters .filter .header button {
  border-radius: 0px;
  padding: 0px;
  background-color: transparent;
  color: #9496a0;
  font-size: 11px;
}

.realestate-results .filters-section .filters .filter .header .toggle-btn {
  margin-top: 4px;
  transition: 0.1s all linear;
}

.realestate-results .filters-section .filters .filter ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 1rem;
  overflow: hidden;
  height: 0px;
  transition: 0.1s all linear;
}

.realestate-results .filters-section .filters .filter.active ul {
  height: 100%;
}

.realestate-results
  .filters-section
  .filters
  .filter.active
  .header
  .toggle-btn {
  transform: rotate(180deg);
  margin-top: -4px;
}

.realestate-results .filters-section .filters .filter ul li label {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.realestate-results .filters-section .filters .filter ul li label span {
  color: #9496a0;
}

.realestate-results
  .filters-section
  .filters
  .filter
  ul
  li
  label
  input[type="radio"] {
  padding: 0px;
  width: auto;
}

.realestate-results .filters-section .filters-action {
  margin-top: 2rem;
  text-align: right;
}

.realestate-results .filters-section .filters-action button {
  background-color: transparent;
  padding: 0rem;
  border-radius: 0px;
  text-decoration: underline;
  color: #0084d1;
  font-size: 14px;
  font-weight: 400;
}

.realestate-results .all-properties-section {
  width: 100%;
}

.realestate-results .all-properties-section .sort {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 2rem;
}

.realestate-results .all-properties-section .sort svg {
  color: #626573;
}

.realestate-results .all-properties-section .sort span,
.realestate-results .all-properties-section .sort select {
  font-size: 14px;
  color: #090041;
  font-weight: 400;
  background-color: transparent;
}

.realestate-results .all-properties-section .sort span {
  font-weight: 500;
}

.realestate-results .all-properties-section .properties .list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.realestate-results .all-properties-section .properties .list .property {
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  display: flex;
  flex-direction: column;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-image {
  height: 150px;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-image
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem 1rem 0rem 0rem;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details {
  padding: 1rem;
  width: 100%;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  h3 {
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
  color: #000;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul {
  display: flex;
  gap: 0px 1rem;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li
  span,
.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li
  a {
  font-size: 12px;
  font-weight: 400;
  color: #0084d1;
  line-height: 18px;
  text-decoration: underline;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li
  p {
  color: #878da4;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 4px;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li {
  position: relative;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li:first-child::after,
.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  ul
  li:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
  width: 3px;
  height: 3px;
  background-color: #c4c4c4;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  h4 {
  font-size: 11px;
  color: #000;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 8px;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  p {
  font-size: 11px;
  color: #000;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.realestate-results
  .all-properties-section
  .properties
  .list
  .property
  .property-details
  button {
  padding: 11px 1rem;
  display: block;
  margin: 1rem 0rem 0rem auto;
}

@media only screen and (min-width: 768px) {
  .realestate-results .content-wrapper {
    flex-direction: row;
    gap: 4rem;
  }

  .realestate-results .filters-section {
    max-width: 225px;
    width: 100%;
  }

  .realestate-results .all-properties-section .sort {
    justify-content: flex-end;
  }

  .realestate-results .all-properties-section .properties .list .property {
    flex-direction: row;
  }

  .realestate-results
    .all-properties-section
    .properties
    .list
    .property
    .property-image {
    height: 250px;
    max-width: 300px;
    width: 100%;
    position: relative;
  }
  .realestate-results
    .all-properties-section
    .properties
    .list
    .property
    .property-image
    .liked_unlike_icon_div {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    background: grey;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  .realestate-results
    .all-properties-section
    .properties
    .list
    .property
    .property-image
    .liked_unlike_icon_div
    > img {
    height: 18px;
    width: 18px;
  }

  .realestate-results
    .all-properties-section
    .properties
    .list
    .property
    .property-image
    img {
    border-radius: 1rem 0rem 0rem 1rem;
    height: 100%;
    width: 100%;
  }
}

/*Tab in Hero page*/

.realestate-hero .services-tabs:nth-child(2) {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 25%;
}
@media only screen and (min-width: 1024px) {
  .realestate-hero .services-tabs:nth-child(2) {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    gap: 7%;
  }
  /* .realestate-hero .services-tabs:nth-child(2) li {
    width: 13%;
  } */
  .realestate-works .content-wrapper section:last-child ul li {
    height: 300px;
  }
  .realestate-works .content-wrapper section:last-child ul li div {
    margin-top: -20px;
  }
}
.realestate-hero .services-tabs:nth-child(2) li {
  background-color: initial;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  cursor: pointer;
}
.realestate-hero .services-tabs:nth-child(2) li p {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  color: #666666;
}
.realestate-hero .services-tabs:nth-child(2) li.active {
  /* padding: 4px 3px; */
  border-bottom: 2px solid #0084d1;
}
.realestate-hero .services-tabs:nth-child(2) li.active p {
  /* padding: 4px 3px; */
  color: #0084d1;
  text-align: center;
}
