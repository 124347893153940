.details .all-data {
  margin: 3% 1%;
}
.details .all-data p:first-child {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #585858;
}
.details .all-data p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #828597;
}
.details .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
}
.details .btn p {
  cursor: pointer;
  color: #0084d1;
  font-weight: 500;
}
.details .btn button {
  position: static;
  width: 180px;
}
@media only screen and (min-width: 1024px) {
  .details .btn {
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
    gap: 5%;
  }
}
