.financial-plan-details {
  background: linear-gradient(180deg, #e0f2ff 12.38%, #ffffff 99.97%);
}

.financial-plan-details .plan section:first-child {
  width: 100%;
}

.financial-plan-details .plan {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.financial-plan-details section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.financial-plan-details .back-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1%;
}
.financial-plan-details .back-btn p,
.financial-plan-details .back-btn svg {
  color: #979797;
}
.financial-plan-details h2 {
  font-weight: 500;
  color: #272727;
  font-size: 24px;
  margin: 2rem 0rem;
}

.financial-plan-details .plan-card {
  background-color: #fff;
  box-shadow: 0px 4px 20px 1px rgba(88, 88, 88, 0.1);
  border-radius: 8px;
  padding: 1rem 1.25rem;
}

.financial-plan-details section .details h3 {
  color: #0084d1;
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.financial-plan-details section .details .pricings {
  margin-bottom: 1rem;
}

.financial-plan-details section .details .pricings p {
  font-size: 13px;
  color: #585858;
  margin-bottom: 6px;
}

.financial-plan-details section .details .pricings ul {
  display: flex;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;
}

.financial-plan-details section .details .pricings ul li {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.financial-plan-details section .details .pricings ul li span {
  color: #585858;
  font-size: 1rem;
  font-weight: 400;
}

.financial-plan-details section .details .pricings ul li span:last-child {
  color: #0084d1;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: 1rem;
}

.financial-plan-details section .details .price {
  color: #0084d1;
  font-size: 1.25rem;
  font-weight: 500;
}

.financial-plan-details section .details .desc {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #585858;
}

.financial-plan-details section .details .benefits {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 1rem;
}

.financial-plan-details section .details .benefits li {
  display: flex;
  gap: 0.5rem;
}

.financial-plan-details section .details .benefits li p {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.financial-plan-details section .details .benefits li svg {
  background: rgba(124, 213, 24, 0.2);
  color: #88d92f;
  /* border: 2px solid #7CD518; */
  margin-top: 4px;
  width: 13px;
  background-color: #e3f3ce;
  height: 13px;
  border-radius: 50%;
}

.financial-plan-details section .details .addons p {
  font-size: 13px;
  line-height: 18px;
  color: #585858;
  font-weight: 500;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-bottom: 0.75rem;
}

.financial-plan-details section .details .addons p input {
  width: 16px;
  height: 16px;
}

.financial-plan-details section .details .addons ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.financial-plan-details section .details .addons ul li {
  display: flex;
  gap: 4px;
}

.financial-plan-details section .details .addons ul li input {
  width: 13px;
  height: 13px;
}

.financial-plan-details section .details .addons ul li p,
.financial-plan-details section .details .addons-desc {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #585858;
}

.financial-plan-details section .details .addons ul li span {
  font-size: 11px;
  font-weight: 500;
  color: #272727;
  margin-left: 0.5rem;
}

.financial-plan-details section .documents h3 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 24px;
  color: #272727;
  margin-bottom: 1rem;
  font-weight: 500;
}
.financial-plan-details section .documents p {
  font-size: 14px;
  color: #585858;
  font-weight: 400;
}
.financial-plan-details section .documents ol {
  padding-left: 1rem;
}

.financial-plan-details section .documents ol li {
  list-style: decimal;
  font-size: 14px;
  color: #585858;
  font-weight: 400;
  text-transform: capitalize;
}

.financial-plan-details section .payment h3 {
  font-size: 24px;
  color: #272727;
  margin-bottom: 1rem;
  font-weight: 500;
}

.financial-plan-details section .payment {
  width: 100%;
}

.financial-plan-details section .payment p {
  font-size: 16px;
  color: #272727;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.financial-plan-details section .payment .details {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px dashed #adadad;
}

.financial-plan-details section .payment .total {
  font-size: 24px;
  margin-top: 0.5rem;
}

.financial-plan-details section .payment .terms {
  font-size: 10px;
  color: #888888;
}

.financial-plan-details section .payment button {
  margin-top: 3rem;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .financial-plan-details .plan {
    flex-direction: row;
  }

  .financial-plan-details section {
    height: max-content;
  }

  .financial-plan-details section .payment {
    width: 350px;
    flex: 1;
  }
}
