.container {
 height: 100%;
 padding-top: 80px;
 width: 60%;
 margin: auto;
 margin: 20px auto;
}
.navigator_link {
 color: #000;
 font-family: Poppins;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 100%;
}
/* list heder css start*/
.list_header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-top: 10px;
}
.filter_div {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 column-gap: 0.9rem;
}
.search_div {
 position: relative;
}
.search_img {
 position: absolute;
 right: 0.8rem;
 height: 1rem;
 width: 1rem;
 top: 0.8rem;
 cursor: pointer;
 /* bottom:50%;  */
}
.search_input {
 width: 258px;
 height: 40px;
 padding: 10px 26px 10px 18px;
 border-radius: 8px;
 border: 1px solid #eee;
 background: #fff;
}

.search_input::-moz-focus .search_img {
 display: none;
 cursor: pointer;
}
.select_property_type {
 display: flex;
 border-radius: 8px;
 background: #d5dbff;
 /* width: 167px; */
 /* padding: 10px; */
 height: 40px;
 padding-right: 10px;
 text-align: center;
 -webkit-appearance: none;
 -moz-appearance: none;
}
.select_property_type_div {
 background-color: #d5dbff;
 display: flex;
 width: 167px;
 border-radius: 8px;
 align-items: center;
 justify-content: center;
 /* padding-right: 10px; */
}
.add_property_btn {
 display: flex;
 justify-content: space-evenly;
 align-items: center;
 white-space: nowrap;
 border-radius: 48px;
 border: 1px solid #0084d1;
 background: #fff;
 color: #000;
 width: 167px;
 height: 40px;
 padding: 10px 16px;
 align-items: center;
 gap: 10px;
 flex-shrink: 0;
 color: #0084d1;
}
.no_data {
 display: flex;
 justify-self: center;
 align-items: center;
 margin: 2rem 0rem;
 color: #000;
 font-family: Poppins;
 font-size: 20px;
 font-style: normal;
 font-weight: 600;
 line-height: 100%;
}

/* list header :end */

@media (max-width: 600px) {
 .container {
  width: 90%;
  height: 100%;
 }
 .filter_div {
  flex-direction: column;
  row-gap: 0.5rem;
 }
 .list_header {
  flex-direction: column;
  row-gap: 0.5rem;
 }
}
