.head p:first-child {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 0px;
  text-align: center;
  color: #0084d1;
}
.head p:last-child {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #585858;
}
.outer-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3%;
}
.box {
  width: 100%;
  border-radius: 24px;
  box-shadow: 0px 4px 20px rgba(158, 158, 158, 0.2);
  padding: 20px;
  /* height: 400px; */
}
.box .info p:first-child {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #0f1c49;
}
.box .info p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #585858;
  margin-top: 2%;
}

.box .info p:nth-child(3) {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #828597;
  margin-top: 10%;
}
.box .info .btn {
  display: flex;
  justify-content: center;
  margin-top: 7%;
}
.box .image {
  height: 100px;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-left: 10%;
}

.box .load {
  background: url("../../../../Assets/Images/load.svg");
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
}
@media only screen and (min-width: 1024px) {
  .box {
    width: 60%;
    height: 400px;
  }
  .box .info .btn {
    margin: 2%;
  }

  .box .image {
    height: 120px;
    margin-left: 0;
  }
}
