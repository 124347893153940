.container {
 width: 65%;
 margin: auto;
}.back_btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
    background-color: #c9c9c9;
    width: max-content;
    padding: 0.1rem 0.5rem;
    border-radius: 1rem;
    margin-bottom: 1rem
}
.primary_img_box {
 height: 20rem;
 width: 100%;
 border-radius: 8px;
 border: 0.5px solid var(--paragraph, #585858);
 background: #c9c9c9;
 display: flex;
 justify-content: center;
 align-items: center;
 margin: 1rem 0rem;
}
.main_head {
 color: #000;
 font-family: Poppins;
 font-size: 32px;
 font-style: normal;
 font-weight: 600;
 text-align: center;
 line-height: 100%;
 margin-bottom: 1rem;
}
.subhead {
 color: var(--paragraph, #585858);
 text-align: center;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
}
.location {
 color: #000;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
 margin-top: 1.5rem;
 text-decoration: underline;
}
.info {
 color: var(--paragraph, #585858);
 font-family: Poppins;
 font-size: 12px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
}
.add_img_btn {
 height: 50px;
 flex-shrink: 0;
 background-color: #fff;
 border: 1px solid #8b8b8b;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 0.5rem;
 column-gap: 0.2rem;
 border-radius: 2px;
 cursor: pointer;
}
.upload_image_status {
 width: 100%;
 border-radius: 2px;
 background: #FFF96E;
 height: 40px;
 margin: 4rem 0rem 2rem 0rem;
 display: grid;
 align-items: center;
 justify-content: center;
 color: var(--paragraph, #585858);
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 400;
 line-height: 20px;
 cursor: pointer;
}
.container > label {
 border-radius: 48px;
 border: 1px solid #0084D1;
 color:#0084D1;
 background: #fff;
 height: 42px;
 width: 60%;
 text-align: center;
 margin: auto;
 display: flex;
 align-items: center;
 column-gap: 0.2rem;
 justify-content: center;
 margin-top: 1rem;
 cursor: pointer;
}
.add_more_photo_btn > label {
 color: #999;
 font-family: Poppins;
 font-size: 14px;
 font-style: normal;
 font-weight: 500;
 line-height: 20px;
}
.preview_img_div {
 width: 80%;
 margin: auto;
 /* height: 300px; */
}.img_preview_card{
    position: relative;
    width: 100%;
    margin-bottom: 1rem;
}
.cancel_icon {
 position: absolute;
 right: 1rem;
 top: 0.8rem;
 height: 1.2rem;
 width: 1.2rem;
 cursor: pointer;
}
.radio_btn_div {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 column-gap: 0.5rem;
}.radio_btn_div>input{
    height: 1.2rem;
    width: 1.2rem;
}.back_btn{
    align-items: center;
    background-color: white;
    border-radius: 0;
    color: #979797;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    gap: 8px;
    padding: 0;
}

@media (max-width: 600px) {
    .container{
        width: 100%;
    }
}